import React, { memo, useContext } from "react";
import { useIntersectionObserverRef } from "rooks";
import styled from "styled-components";
import { IFilter } from "../../contexts/FiltersContext";
import ShoppingListContext from "../../contexts/ShoppingListContext";
import TrackingContext from "../../contexts/TrackingContext";
import { ArticleDetailsWithVariantsFragment } from "../../generated/graphql";
import ArticlesCategoryHeader from "./ArticlesCategoryHeader";
import ArticleTile from "./ArticleTile";
import ImageTile from "./ImageTile";

const GridContainer = styled("div")(({ theme }) => ({
    display: "grid",
    gap: theme.baseUnit * 2,
    gridTemplateColumns: "repeat(2, 1fr)",
    gridAutoRows: "min-content",
    gridAutoFlow: "row dense",
    marginBottom: theme.baseUnit * 2,
    [theme.media("md")]: {
        gridTemplateColumns: "repeat(4, 1fr)"
    },
    [theme.media("xl")]: {
        gap: theme.baseUnit * 4
    }
}));

interface IArticlesCategoryProps {
    categoryName?: string;
    categoryId: string;
    articles: Array<ArticleDetailsWithVariantsFragment>;
    filters: Array<IFilter>;
    onSeenLast: () => void;
    onArticleClick: (article: ArticleDetailsWithVariantsFragment) => void;
    documentUrl: string | null;
}

const ArticlesCategory: React.FC<IArticlesCategoryProps> = ({
    categoryName,
    categoryId,
    articles,
    filters,
    onSeenLast,
    onArticleClick,
    documentUrl
}) => {

    const [lastItemRef] = useIntersectionObserverRef(entries => {

        const lastEntry = entries && entries[0];

        if (lastEntry?.isIntersecting) {
            onSeenLast();
        }
    }, { threshold: 0.5 });

    const shoppingListContext = useContext(ShoppingListContext);
    const trackingContext = useContext(TrackingContext);

    return (
        <React.Fragment>
            {categoryName && (
                <ArticlesCategoryHeader id={categoryId} name={categoryName} />
            )}
            <GridContainer>
                {articles.map((article, index) => {

                    const isLastItem = (index + 1) >= articles.length;

                    const onListAddClick = () => {
                        if (shoppingListContext.has(article.id, true)) {
                            shoppingListContext.remove(article.id);
                        } else {
                            shoppingListContext.add(article.id);
                
                            if (article) {
                                trackingContext.trackEvent("listItemAdded", {
                                    articleId: article.id,
                                    offeredPrice: article.offeredPrice,
                                    normalPrice: article.normalPrice || undefined,
                                    onVariantsList: false
                                });
                            }
                        }
                    };
    
                    if (article.id.startsWith("Image")) {
                        return (
                            <ImageTile
                                key={article.id}
                                article={article}
                                ref={isLastItem ? lastItemRef : undefined}
                                documentUrl={documentUrl}
                            />
                        );
                    }
                    else {

                        return (
                            <ArticleTile
                                key={article.id}
                                article={article}
                                ref={isLastItem ? lastItemRef : undefined}
                                filters={filters}
                                onClick={() => onArticleClick(article)}
                                clickable={article.hasVariants}
                                noExpand={false}
                                isAdded={shoppingListContext.has(article.id, true)}
                                onAddClick={onListAddClick}
                            />
                        );
                    }
                })}
            </GridContainer>
        </React.Fragment>
    );
};

export default memo(ArticlesCategory);