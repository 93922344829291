import { DefaultTheme } from "styled-components";

const textGrey = "rgb(64, 66, 68)";
const white = "rgb(255, 255, 255)";

const theme: DefaultTheme = {
    baseUnit: 8,
    palette: {
        text: textGrey,
        background: {
            main: "rgb(234, 234, 234)",
            contrastText: textGrey
        },
        paper: {
            main: "rgb(248, 248, 248)",
            contrastText: "rgb(0, 0, 0)"
        },
        /**
         * EDEKA blue
         */
        primary: {
            main: "rgb(30, 94, 164)",
            contrastText: white
        },
        /**
         * EDEKA yellow
         */
        secondary: {
            main: "rgb(255, 230, 0)",
            contrastText: textGrey
        },
        /**
         * accent green
         * button background is 0.6 opacity 
         */
        tertiary: {
            main: "rgb(0, 144, 54)",
            contrastText: white
        },
        quaternary: {
            main: "rgb(220, 36, 25)",
            contrastText: white
        },
        drawer: {
            main: "rgb(52, 53, 54)",
            contrastText: white
        },
        rule: "rgb(196, 196, 196)",
        white,
        black: "rgb(0, 0, 0)"
    },
    typography: {
        regular: 400,
        bold: 700,
        families: {
            default: "'Work Sans', sans-serif",
            headline: "'Plak', sans-serif",
            prices: "'Anton', sans-serif"
        }
    },
    breakpoints: {
        sm: "(min-width: 600px)",
        md: "(min-width: 900px)",
        lg: "(min-width: 1200px)",
        xl: "(min-width: 1536px)"
    },
    media: (breakpoint: keyof DefaultTheme["breakpoints"]) => {
        return "@media " + theme.breakpoints[breakpoint];
    },
    zIndex: {
        bars: 30,
        flying: 10,
        overlay: 20
    },
    layout: {
        headerHeight: 88,
        footerHeight: 80
    }
};

export default theme;