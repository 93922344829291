import { useCallback, useEffect, useState } from "react";
import { throttle } from "lodash";
import propsAreEqual from "../utils/propsAreEqual";

interface IScrollInfo {
    scrolledToTop: boolean;
    scrolled1Vh: boolean;
    scrolledToBottom: boolean;
}

export default function useScrollInfo(offsetBottom = 0, throttleMs = 250) {

    const [scrollInfo, setScrollInfo] = useState<IScrollInfo>({
        scrolledToTop: true,
        scrolled1Vh: false,
        scrolledToBottom: true
    });

    const setScrollInfoIfDiffer = (newScrollInfo: IScrollInfo) => {

        if (!propsAreEqual(scrollInfo, newScrollInfo, ["scrolledToTop", "scrolled1Vh", "scrolledToBottom"])) {
            setScrollInfo(newScrollInfo);
        }
    };

    const onScroll = useCallback(throttle(() => {
    
        if (!document || !document.scrollingElement) {
            setScrollInfoIfDiffer({
                scrolledToTop: true,
                scrolled1Vh: false,
                scrolledToBottom: true
            });
        } else {
            setScrollInfoIfDiffer({
                scrolledToTop: scrollY === 0,
                scrolled1Vh: scrollY > window.innerHeight,
                scrolledToBottom: (scrollY + window.innerHeight + offsetBottom) > document.scrollingElement.scrollHeight
            });
        }
    }, throttleMs), [scrollInfo]);

    useEffect(() => {

        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, [onScroll]);

    return scrollInfo;
}