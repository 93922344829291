import styled from "styled-components";
import dontForward from "../../utils/dontForward";

const ArticleTileDisplay = styled("div").withConfig(dontForward("horizontal"))<{ horizontal: boolean }>(({ horizontal, theme }) => ({
    position: "relative",
    backgroundColor: "rgb(255, 255, 255)",
    height: horizontal ? undefined : 256,
    flex: horizontal ? "0 0 calc(50% - 8px)" :  undefined,
    ...(horizontal && ({
        minHeight: 256
    })),
    [theme.media("md")]: {
        flex: horizontal ? "0 0 calc(100% - 200px)" :  undefined
    },
    display: "flex",
    alignItems: "center"
}));

export const ArticleTileDisplaySmall = styled("div")(({ theme }) => ({
    position: "relative",
    backgroundColor: "rgb(255, 255, 255)",
    flex: "0 0 104px",
    minHeight: 86,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.media("md")]: {
        flex: "0 104px"
    }
}));

export default ArticleTileDisplay;