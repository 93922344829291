import React, { createContext } from "react";
import { useLocalstorageState } from "rooks";
import { MarketInfoDetailsFragment, useMarketsQuery } from "../generated/graphql";

interface IMarketInfoContext {
    market: MarketInfoDetailsFragment | null;
    setMarketId: (marketId: string | null) => void;
    hasSetMarket: boolean;
    markets: Array<MarketInfoDetailsFragment>;
}

const MarketInfoContext = createContext<IMarketInfoContext>({
    market: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setMarketId: () => {},
    hasSetMarket: false,
    markets: []
});

export const MarketInfoProvider: React.FC = ({ children }) => {

    const [marketId, setMarketId] = useLocalstorageState<string | null>("selected_market", null);

    const { data } = useMarketsQuery();

    const markets = data?.markets || [];
    const selectedMarket = markets.find(m => m.id === marketId) || null;

    const value = {
        market: selectedMarket || markets[0] || null,
        setMarketId,
        hasSetMarket: !!marketId,
        markets
    };

    return (
        <MarketInfoContext.Provider value={value}>
            {children}
        </MarketInfoContext.Provider>
    );
};

export default MarketInfoContext;