import styled from "styled-components";
import valuesCss from "../utils/valuesCss";

const Modal = styled("div")(({ theme }) => ({
    zIndex: theme.zIndex.overlay,
    maxWidth: "80%",
    background: theme.palette.white,
    padding: theme.baseUnit * 2,
    minWidth: "60vw",
    maxHeight: "80vh",
    overflowY: "auto",
    position: "relative",
    "& > h2" : {
        fontSize: 24,
        marginBottom: theme.baseUnit,
        marginRight: (24 + 14)
    },
    "& > hr" : {
        margin: valuesCss("px", theme.baseUnit * 2, -theme.baseUnit * 2)
    },
    [theme.media("sm")]: {
        minWidth: 400
    }
}));

export default Modal;