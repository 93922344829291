import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import React, { createContext, useEffect, useMemo, useState } from "react";

interface ITrackingEvents {
    categoryClick: {
        handoutId: string;
        id: string;
    };
    categoryScrolled: {
        handoutId: string;
        id: string;
    };
    listItemAdded: {
        articleId: string;
        offeredPrice: number;
        onVariantsList: boolean;
        normalPrice?: number;
    };
    openVariants: {
        handoutId: string;
        categoryId?: string;
        articleId: string;
    };
    listShare: {
        itemCount: number;
    };
    filter: {
        id: string;
        categoryId?: string;
    };
    // TODO: implement
    newsletterClick: {
        handoutId: string;
    };
    // TODO: implement
    newsletterSubscribe: {
        handoutId: string;
    }
}

interface ITrackingContext {
    trackEvent: <K extends keyof ITrackingEvents>(event: K, details: ITrackingEvents[K]) => void;
}

const TrackingContext = createContext<ITrackingContext>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    trackEvent: () => {}
});

export const TrackingContextProvider: React.FC = ({ children }) => {

    const [analytics, setAnalytics] = useState<Analytics | null>(null);

    useEffect(() => {

        const onAcceptCookies = () => {

            const analytics = getAnalytics(initializeApp((window as any).firebaseConfig));

            setAnalytics(analytics);
        };

        window.addEventListener("CookiebotOnAccept", onAcceptCookies, false);

        return () => window.removeEventListener("CookiebotOnAccept", onAcceptCookies);
    }, []);

    const value: ITrackingContext = useMemo(() => {

        if (!analytics) {
            return {
                trackEvent: (event: string, details: Record<string, unknown>) => {
                    console.info("[no tracking enabled] " + event, details);
                }
            };
        }

        return {
            trackEvent: (event: string, details: Record<string, unknown>) => {
                try {
                    logEvent(analytics, event, details);
                } catch (error) {
                    console.error("error calling firebase analytics logEvent");
                }
            }
        };

    }, [analytics]);

    return (
        <TrackingContext.Provider value={value}>
            {children}
        </TrackingContext.Provider>
    );
};

export default TrackingContext;