import React from "react";
import { SvgIconProps } from "./SvgIconProps";

const FavListIcon: React.FC<SvgIconProps> = ({ color, ...props }) => (
    <svg viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinecap="square" strokeMiterlimit="3" {...props}>
        <path d="M550.419 921.579h5.893M547.657 918.157h8.655" fill="none" stroke={color || "currentColor"} strokeWidth="1.2px" transform="matrix(.93565 0 0 1 -502.152 -912.067)"/>
        <path d="M539.301 926.391h12.421v2.578c0 1.341 1.096 3.022 3.328 3.022h-13.284c-1.351 0-2.477-1.158-2.477-3.358 0-1.774.012-2.242.012-2.242z" fill="none" stroke={color || "currentColor"} strokeWidth="1.2px" strokeLinecap="round" transform="translate(-537.392 -912.067)"/>
        <path d="m544.89 926.391-.106-12.424 1.314.903 1.437-.903 1.253.964 1.589-.964 1.161.75 1.437-.75 1.222.933 1.497-.933 1.376 1.117 1.589-1.117V928.5s.076 3.491-3.492 3.491h-.117c-2.232 0-3.328-1.681-3.328-3.022v-2.578h-6.832z" fill="none" stroke={color || "currentColor"} strokeWidth="1.2px" transform="translate(-537.392 -912.067)"/>
    </svg>
);

export default FavListIcon;