import styled from "styled-components";
import CSSTransition from "react-transition-group/CSSTransition";

const SlideRight = styled(CSSTransition).attrs({ classNames: "slideRight", timeout: 500 })`
&.slideRight-enter {
    transform: translateX(100%);
}
&.slideRight-enter-active {
    transform: translateX(0%);
}
&.slideRight-exit {
    transform: translateX(0%);
}
&.slideRight-exit-active {
    transform: translateX(100%);
}
&.slideRight-enter-active,
&.slideRight-exit-active {
    transition: transform 500ms;
}
`;

export default SlideRight;