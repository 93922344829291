import React from "react";
import styled, { useTheme } from "styled-components";
import AllArticlesIcon from "../../common/icons/AllArticlesIcon";
import CheckmarkIcon from "../../common/icons/CheckmarkIcon";
import ScrollSlider from "../../common/ScrollSlider";
import SecondaryButton from "../../common/SecondaryButton";
import dontForward from "../../utils/dontForward";
import makeTextBreakable from "../../utils/makeTextBreakable";
import positionCss from "../../utils/positionCss";

export interface ICategoryMenuItem {
    id: string;
    name: string;
    seen: boolean;
}

const CategoryButtonBase = styled(SecondaryButton)
    .attrs({ bigPadding: true })
    .withConfig(dontForward("active"))<{ active: boolean }>(({ theme, active }) => ({
    maxWidth: 196,
    position: "relative",
    ...(active && {
        opacity: 1,
        border: "none",
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    })
}));

const AllCategoriesButton: React.FC<{ active: boolean } & React.HTMLAttributes<HTMLButtonElement>> = ({ active, ...props }) => (
    <CategoryButtonBase active={active} {...props}>
        <AllArticlesIcon color="currentColor" style={{ height: "1em", marginRight: "0.5em" }} />
        <span>Alle</span>
    </CategoryButtonBase>
);

const SeenIcon = styled(CheckmarkIcon)({
    ...positionCss("abs", "right"),
    height: "0.5em",
    margin: "auto 0.4em"
});

const CategoryButton: React.FC<Omit<ICategoryMenuItem, "id"> & { active: boolean } & React.HTMLAttributes<HTMLButtonElement>> = (({ name, active, seen, ...props }) => {

    const theme = useTheme();

    return (
        <CategoryButtonBase active={active} {...props}>
            {makeTextBreakable(name)}
            {seen && (
                <SeenIcon color={active ? theme.palette.white : theme.palette.tertiary.main} />
            )}
        </CategoryButtonBase>
    );
});

interface ICategoryMenuProps {
    categories: Array<ICategoryMenuItem>;
    /**
     * no active category ID means: all articles
    */
    activeId?: string;
    onCategoryClick: (id: string) => void;
    onAllCategoriesClick: () => void;
}

const CategoryMenu: React.FC<ICategoryMenuProps> = ({ categories, activeId, onCategoryClick, onAllCategoriesClick }) => {

    const activeCategoryIndex = categories.findIndex(c => c.id === activeId);

    return (
        <ScrollSlider scrollToItemIndex={activeCategoryIndex >= 0 ? activeCategoryIndex + 1 : undefined}>
            <AllCategoriesButton active={!activeId} onClick={onAllCategoriesClick} />
            {categories.map(({ id, name, seen }) => (
                <CategoryButton
                    key={id}
                    name={name}
                    seen={seen}
                    active={activeId === id}
                    onClick={() => onCategoryClick(id)}
                />
            ))}
        </ScrollSlider>
    );
};

export default CategoryMenu;