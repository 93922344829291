import React from "react";
import { SvgIconProps } from "./SvgIconProps";

const TileLoader: React.FC<SvgIconProps> = ({ color, ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" {...props}>
        <rect x="33.5" y="33.5" width="16" height="16" fill={color}>
            <animate attributeName="opacity" values="0.25;0.75;0.75" keyTimes="0;0.25;1" dur="0.8s" repeatCount="indefinite" begin="0s" calcMode="discrete"></animate>
        </rect>
        <rect x="50.5" y="33.5" width="16" height="16" fill={color}>
            <animate attributeName="opacity" values="0.25;0.75;0.75" keyTimes="0;0.25;1" dur="0.8s" repeatCount="indefinite" begin="0.2s" calcMode="discrete"></animate>
        </rect>
        <rect x="33.5" y="50.5" width="16" height="16" fill={color}>
            <animate attributeName="opacity" values="0.25;0.75;0.75" keyTimes="0;0.25;1" dur="0.8s" repeatCount="indefinite" begin="0.6s" calcMode="discrete"></animate>
        </rect>
        <rect x="50.5" y="50.5" width="16" height="16" fill={color}>
            <animate attributeName="opacity" values="0.25;0.75;0.75" keyTimes="0;0.25;1" dur="0.8s" repeatCount="indefinite" begin="0.4s" calcMode="discrete"></animate>
        </rect>
    </svg>
);

TileLoader.defaultProps = {
    color: "currentColor"
};

export default TileLoader;