import styled from "styled-components";

const TextLink = styled("a")(({ theme }) => ({
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&:hover": {
        textDecoration: "underline"
    }
}));

export default TextLink;