import React from "react";
import styled from "styled-components";
import { IFilter } from "../../contexts/FiltersContext";
import PossibleImage from "../PossibleImage";

const AttributeIcon = styled(PossibleImage)({
    height: "1em",
    marginLeft: "0.2em",
    verticalAlign: "middle",
    filter: "invert(48%) sepia(79%) saturate(2476%) hue-rotate(80deg) brightness(100%) contrast(70%)",
    "& + &": {
        marginLeft: 0
    }
});

export function renderAttributeIcons(attributes: Array<string>, filters: Array<IFilter>) {

    const attributeFilterInfos = attributes
        .map(a => filters.find(f => f.id === a))
        .filter((f): f is IFilter => !!f);

    return attributeFilterInfos
        .filter(({ iconUrl }) => !!iconUrl)
        .map(({ id, label, iconUrl }) => (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <AttributeIcon key={id} src={iconUrl!} alt={label} />
        ));
}

export default AttributeIcon;