import { addWeeks } from "date-fns";
import React, { createContext, useCallback, useEffect } from "react";
import { useLocalstorageState } from "rooks";

interface IStoredCategoriesSeen {
    endDate: string;
    handoutId: string;
    categoriesSeen: Array<string>;
}

export interface ICategorySeenContext {
    setCategorySeen: (handoutId: string, categoryId: string) => void;
    hadSeenCategory: (handoutId: string, categoryId: string) => boolean;
}

const noopHandlers = {
    // eslint-disable-next-line
    setCategorySeen: (handoutId: string, categoryId: string) => {},
    // eslint-disable-next-line
    hadSeenCategory: (handoutId: string, categoryId: string) => false
};

const CategorySeenContext = createContext<ICategorySeenContext>(noopHandlers);

export default CategorySeenContext;

const localStorageKey = "categories-seen";

export const CategorySeenProvider: React.FC = ({ children }) => {

    const [handoutCategoriesSeen, setHandoutCategoriesSeen] = useLocalstorageState<Array<IStoredCategoriesSeen>>(localStorageKey, []);

    useEffect(() => {

        const now = new Date();

        try {
            setHandoutCategoriesSeen(handoutCategoriesSeen.filter(c => new Date(c.endDate) > now));

        } catch (error) {

            console.error(`corrupted entries found in local-storage. Cleaning up "${localStorageKey}" key`, error);
            setHandoutCategoriesSeen([]);
        }

    }, []);

    const setCategorySeen = useCallback((handoutId: string, categoryId: string) => {

        const existingHandout = handoutCategoriesSeen.find(c => c.handoutId === handoutId);
        const handout = existingHandout || { handoutId, endDate: addWeeks(new Date(), 4).toJSON(), categoriesSeen: [] };

        if (!existingHandout) {
            handoutCategoriesSeen.push(handout);
        }

        if (!handout.categoriesSeen.some(c => c === categoryId)) {
            handout.categoriesSeen.push(categoryId);
        } else {
            return;
        }

        setHandoutCategoriesSeen([ ...handoutCategoriesSeen ]);

    }, [handoutCategoriesSeen, setHandoutCategoriesSeen]);

    const hadSeenCategory = (handoutId: string, categoryId: string) => !!handoutCategoriesSeen
        .find(h => h.handoutId === handoutId)?.categoriesSeen
        .some(c => c === categoryId);

    return (
        <CategorySeenContext.Provider value={{ setCategorySeen, hadSeenCategory }}>
            {children}
        </CategorySeenContext.Provider>
    );
};