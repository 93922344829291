import styled from "styled-components";
import CSSTransition from "react-transition-group/CSSTransition";

const FadeLeftRight = styled(CSSTransition).attrs({ classNames: "fade", timeout: 500 })`
display: inline-block;
&.fade-enter {
    opacity: 0;
    transform: translateX(-20%);
}
&.fade-enter-active {
    opacity: 1;
    transform: translateX(0%);
}
&.fade-exit {
    opacity: 1;
    transform: translateX(0%);
}
&.fade-exit-active {
    opacity: 0;
    transform: translateX(20%);
}
&.fade-enter-active,
&.fade-exit-active {
    transition: opacity 500ms, transform 500ms;
}
`;

export default FadeLeftRight;