import React from "react";
import { useState } from "react";
import styled, { CSSObject } from "styled-components";
import valuesCss from "../../utils/valuesCss";

const categoryBannerStyles: CSSObject = {
    marginBottom: 16
};

const CategoryBannerFallback = styled("h2")(({ theme }) => ({
    ...categoryBannerStyles,
    background: theme.palette.white,
    padding: valuesCss("px", theme.baseUnit * 4, theme.baseUnit * 2),
    fontFamily: theme.typography.families.headline,
    fontWeight: theme.typography.bold,
    fontSize: 26,
    letterSpacing: "0.02em",
    textAlign: "center",
    boxShadow: "0 3px 3px rgba(0, 0, 0, 0.03)",
    position: "relative",
    "&:after": {
        content: "''",
        display: "block",
        position: "absolute",
        top: "100%",
        left: 0,
        right: 0,
        height: 12,
        backgroundImage: "url(/assets/images/tear-off-h.png)",
        backgroundPosition: "left center",
        backgroundRepeat: "repeat-x",
        backgroundSize: "12px 12px"
    }
}));

const CategoryBannerImage = styled("img")(({ theme }) => ({
    ...categoryBannerStyles,
    width: "100%",
    objectFit: "contain",
    objectPosition: "center",
    height: 76,
    [theme.media("sm")]: {
        minHeight: 96
    }
}));

interface IArticlesCategoryHeaderProps {
    id: string;
    name: string;
}

const ArticlesCategoryHeader: React.FC<IArticlesCategoryHeaderProps> = ({ id, name }) => {

    const [hasImage, setHasImage] = useState(true);

    if (hasImage) {
        return (
            <CategoryBannerImage
                src={`/category-banners/${id}.png`}
                onError={() => setHasImage(false)}
            />
        );
    }

    return (
        <CategoryBannerFallback>
            {name}
        </CategoryBannerFallback>
    );
};

export default React.memo(ArticlesCategoryHeader);