import React from "react";
import { SvgIconProps } from "./SvgIconProps";

const VariantsIcon: React.FC<SvgIconProps> = ({ color, ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="1.5" fill="none" stroke={color || "currentColor"} {...props}>
        <path d="M1.567 1.567h20.147v20.147H1.567zm5.892 26.038h20.147V7.458" strokeWidth="3.133"/>
        <g strokeLinecap="square">
            <path d="M11.704 8.386v6.824" strokeWidth="2.234"/>
            <path d="M47.285 446.13v7.109" strokeWidth="1.89" transform="matrix(0 1.368664 -.959888 0 443.350141 -52.920113)"/>
        </g>
    </svg>
);

export default VariantsIcon;