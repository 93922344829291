import styled from "styled-components";
import OverlayContainer from "../common/OverlayContainer";
import dontForward from "../utils/dontForward";
import valuesCss from "../utils/valuesCss";

const SideMenuContainer = styled(OverlayContainer).withConfig(dontForward("open", "stickTo"))<{ open: boolean, stickTo: "left" | "right" }>(({ open, stickTo, theme }) => ({
    padding: valuesCss("px", theme.baseUnit, 0),
    background: theme.palette.drawer.main,
    color: theme.palette.drawer.contrastText,
    [theme.media("sm")]: {
        width: "300px",
        ...(stickTo === "left" ? {
            right: "auto"
        } : {
            left: "auto"
        })
    },
    ...(open && {
        boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.3)"
    })
}));

export default SideMenuContainer;