import styled from "styled-components";
import dontForward from "../../utils/dontForward";
import PossibleImage from "../PossibleImage";

const fallbackImageUrl = "/assets/images/product-no-image.svg";

const ProductImage = styled(PossibleImage)
    .attrs({ fallback: fallbackImageUrl })
    .withConfig(dontForward("maxHeight"))<{ maxHeight?: number }>(
    ({ theme, maxHeight }) => ({
        width: "100%",
        height: "100%",
        maxHeight,
        objectFit: "contain",
        objectPosition: "center",
        padding: theme.baseUnit
    })
);

export default ProductImage;