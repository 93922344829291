import React, { useContext, useMemo, useRef, useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { useMediaMatch } from "rooks";
import { useTheme } from "styled-components";
import LoadingOverlay from "../../../common/LoadingOverlay";
import FiltersContext from "../../../contexts/FiltersContext";
import OverlayContext from "../../../contexts/OverlayContext";
import TrackingContext from "../../../contexts/TrackingContext";
import CategorySeenContext from "../../../contexts/CategorySeenContext";
import CurrentHandoutContext from "../../../contexts/CurrentHandoutContext";
import useScrollInfo from "../../../hooks/useScrollInfo";
import {
    ArticleDetailsWithVariantsFragment,
    useAllArticlesQuery,
    usePreviewAllArticlesQuery
} from "../../../generated/graphql";
import FilterBar from "../category/FilterBar";
import MoreArticlesButton from "../MoreArticlesButton";
import ScrollLift from "../ScrollLift";
import ArticlesCategory from "../ArticlesCategory";
import HandoutHeader from "../HandoutHeader";

const AllArticles: React.FC = () => {

    const { preview_date } = useParams();
    const location = useLocation();
    const didCurrentHandout = useRef(false);
    
    const theme = useTheme();
    const mediaMd = useMediaMatch(theme.breakpoints.md);
    const mediaLg = useMediaMatch(theme.breakpoints.lg);

    const filterContext = useContext(FiltersContext);
    const trackingContext = useContext(TrackingContext);
    const navigate = useNavigate();

    const pageSize = mediaLg ? 12 : mediaMd ? 9 : 6;

    const limit = useRef(pageSize);
    const offset = 0;

    const result = useAllArticlesQuery({
        skip: !!preview_date,
        variables: {
            offset,
            limit: pageSize,
            filters: filterContext.active
        }
    });
    const previewResult = usePreviewAllArticlesQuery({
        skip: !preview_date,
        variables: {
            atDate: preview_date || "",
            offset,
            limit: pageSize,
            filters: filterContext.active
        }
    });
    
    const handout = result.data?.currentHandout || previewResult.data?.handout;
    const loading = preview_date ? previewResult.loading : result.loading;
    const fetchMore = preview_date ? previewResult.fetchMore : result.fetchMore;

    const articlesInCategories = useMemo(() => {
        const articles = handout?.uniqueArticles || [];
        return articles.reduce((articlesInCategories, article) => {

            if (!articlesInCategories.has(article.categoryId)) {
                articlesInCategories.set(article.categoryId, [article]);
            } else {
                articlesInCategories.get(article.categoryId)?.push(article);
            }
            
            return articlesInCategories;

        }, new Map<string, Array<ArticleDetailsWithVariantsFragment>>());
    }, [handout]);

    const categoriesById = useMemo(() => {

        return handout?.categories.reduce((map, c) => {
            return map.set(c.id, c.name);
        }, new Map<string, string>()) || null;

    }, [handout]);

    const { openedPopupId } = useContext(OverlayContext);
    const { setCategorySeen, hadSeenCategory } = useContext(CategorySeenContext);
    const currentHandoutContext = useContext(CurrentHandoutContext);

    const { scrolledToBottom, scrolled1Vh } = useScrollInfo(100);

    const canLoadMore = limit.current < (handout?.uniqueArticlesCount || 0);
    const canScrollToSeeMore = canLoadMore || !scrolledToBottom;

    const onSeenLastOfCategory = (categoryId: string) => {

        if (openedPopupId) {
            return;
        }

        const handoutId = handout?.id || "";
        const loadedAll = (offset + limit.current) >= (handout?.uniqueArticlesCountAll || 0);
        const articlesInCategoriesArray = Array.from(articlesInCategories);
        const lastRenderedCategory = articlesInCategoriesArray[articlesInCategoriesArray.length - 1];

        const isLastRenderedCategory = categoryId === lastRenderedCategory[0];

        if (isLastRenderedCategory) {

            if (loadedAll) {
                setCategorySeen(handoutId, categoryId);

            } else {

                limit.current = Math.min(
                    Math.max(
                        limit.current + pageSize,
                        handout?.uniqueArticles.length || 0
                    ),
                    handout?.uniqueArticlesCountAll || pageSize
                );
        
                fetchMore({
                    variables: {
                        limit: limit.current,
                        offset
                    }
                });
            }   

        } else if (!hadSeenCategory(handoutId, categoryId)) {
            setCategorySeen(handoutId, categoryId);
        }
    };

    const onMoreClick = () => {
        const halfHeight = Math.round(document.body.clientHeight * 0.75);
        document.scrollingElement?.scrollBy({ top: halfHeight, behavior: "smooth" });
    };

    const onArticleClick = (article: ArticleDetailsWithVariantsFragment) => {

        if (article.hasVariants) {
            navigate("variants/" + article.id);
            trackingContext.trackEvent("openVariants", {
                handoutId: handout?.id || "",
                articleId: article.id
            });
        }
    };

    useEffect(() => {
        if (handout) {            
            const currentHandout = currentHandoutContext.getCurrentHandout(handout?.id);
            if (currentHandout && location?.state?.scroll == true) {
                window.scrollTo(0, currentHandout.scroll);
                limit.current = Math.min(
                    Math.max(
                        limit.current + pageSize,
                        handout?.uniqueArticles.length || 0
                    ),
                    handout?.uniqueArticlesCountAll || pageSize
                );
            } else if (!didCurrentHandout.current) {
                didCurrentHandout.current = true;
                currentHandoutContext.setCurrentHandout(handout?.id, "", "0");
                window.scrollTo(0, 0);
            }
            const onScroll = () => currentHandoutContext.setCurrentHandout(handout?.id, "", window.scrollY);
            window.addEventListener('scroll', onScroll, { passive: true });
            return () => window.removeEventListener('scroll', onScroll);
        }
    }, [handout]);

    if (!loading && (!handout || handout.uniqueArticlesCount === 0)) {

        return(
            <>
                <br />
                <h2>Es gibt momentan keine Angebote</h2>
                <p>Bitte schauen Sie später nochmal vorbei</p>
                <Outlet />
            </>
        );
    }

    return (
        <>
            <LoadingOverlay active={loading} />
            {handout && (
                <HandoutHeader
                    documentUrl={handout?.documentUrl || null}
                />
            )}
            {handout && (
                <FilterBar
                    availableFilters={filterContext.all.map(f => f.id)}
                    allArticlesCount={handout.uniqueArticlesCount}
                    filteredArticlesCount={handout.uniqueFilteredCount}
                />
            )}
            {handout && (
                Array.from(articlesInCategories).map(([categoryId, articles]) => (
                    <ArticlesCategory
                        key={categoryId}
                        categoryId={categoryId}
                        categoryName={categoriesById?.get(categoryId) || ""}
                        articles={articles}
                        filters={filterContext.all}
                        onSeenLast={() => onSeenLastOfCategory(categoryId)}
                        onArticleClick={onArticleClick}
                        documentUrl={handout?.documentUrl || null}
                    />
                ))
            )}
            {canScrollToSeeMore && (
                <MoreArticlesButton onClick={onMoreClick}>
                    mehr Artikel
                </MoreArticlesButton>
            )}
            <ScrollLift show={scrolled1Vh && !scrolledToBottom} />
            <Outlet />
        </>
    );
};

export default AllArticles;