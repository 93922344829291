import styled from "styled-components";
import dontForward from "../../utils/dontForward";
import flexCss from "../../utils/flexCss";

const ArticleTileContainer = styled("article").withConfig(dontForward("horizontal"))<{ horizontal: boolean }>(({ theme, horizontal }) => ({
    backgroundColor: theme.palette.paper.main,
    ...flexCss(horizontal ? "h" : "v", "stretch", "stretch"),
    fontSize: 14,
    position: "relative",
    overflow: "hidden",
    boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)"
}));

export default ArticleTileContainer;