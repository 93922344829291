import styled from "styled-components";

const Chalkboard = styled("div")(({ theme }) => ({
    color: theme.palette.white,
    background: "url(/assets/images/chalkboard.jpg)",
    backgroundRepeat: "repeat",
    "em": {
        color: theme.palette.secondary.main
    }
}));

export default Chalkboard;