import React, { useEffect, useRef, useState } from "react";
import { useMutationObserver, useOnWindowResize } from "rooks";
import styled, { StyledComponentPropsWithRef } from "styled-components";
import PrimaryButton from "../style/PrimaryButton";
import dontForward from "../utils/dontForward";
import flexCss from "../utils/flexCss";
import hideScrollbarCss from "../utils/hideScrollbarCss";
import valuesCss from "../utils/valuesCss";

interface IScrollInfo {
    canScrollRight: boolean;
    canScrollLeft: boolean;
}

function getScrollInfo(element: HTMLElement | null): IScrollInfo {

    if (!element) {
        return {
            canScrollLeft: false,
            canScrollRight: false
        };
    }

    return {
        canScrollLeft: element.scrollLeft > 0,
        canScrollRight: (element.scrollLeft + element.clientWidth) < element.scrollWidth
    };
}

function scrollLeftSmooth(element: HTMLElement, left: number) {

    element.scrollTo({
        left,
        top: 0,
        behavior: "smooth"
    });
} 

function scrollByThirdWidth(element: HTMLElement | null, toRight: boolean) {

    if (element) {

        const scrollAmount = Math.round(element.clientWidth / 3) + element.scrollLeft;

        scrollLeftSmooth(element, toRight ? scrollAmount : -scrollAmount);
    }
}

function getVisibleEdges(element: HTMLElement) {

    const scrollWidth = element.parentElement?.scrollLeft || 0;
    const containerWidth = element.parentElement?.clientWidth || 0;

    return {
        left: element.offsetLeft >= scrollWidth,
        right: (element.offsetLeft + element.clientWidth - scrollWidth) <= containerWidth
    };
}

function scrollIntoView(container: HTMLElement, child: HTMLElement) {

    const { left, right } = getVisibleEdges(child);

    if (!right) {
        scrollLeftSmooth(container, child.offsetLeft - child.clientWidth);

    } else if (!left) {
        scrollLeftSmooth(container, child.offsetLeft);
    }
}

const Container = styled("div")(({ theme }) => ({
    margin: valuesCss("px", theme.baseUnit * 2, 0),
    position: "relative"
}));

const InnerContainer = styled("div")(({ theme }) => ({
    ...flexCss("h", "start", "stretch"),
    overflowX: "auto",
    ...hideScrollbarCss(),
    scrollSnapType: "x proximity",
    scrollPadding: theme.baseUnit * 2,
    "& > *": {
        flex: "0 0 auto",
        marginLeft: theme.baseUnit * 2,
        scrollSnapAlign: "start"
    },
    "& > :not(:first-child)": {
        marginLeft: theme.baseUnit * 2
    },
    "& > :last-child": {
        scrollSnapAlign: "end",
        marginRight: theme.baseUnit * 2
    }
}));

interface IArrowButtonProps {
    dir: "left" | "right";
}

const ArrowButton = styled((props: StyledComponentPropsWithRef<typeof PrimaryButton>) => (
    <PrimaryButton {...props}>
        <svg viewBox="0 0 12 20" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeMiterlimit="1.5">
            <g transform="matrix(1.02708 0 0 .98143 -803.65 -114.118)">
                <path d="m821.717 922.823 16.953-12.317 16.953 12.317" fill="none" stroke="#ffffff" strokeWidth="5.84px" transform="matrix(0 .52332 -.50006 0 1246.69 -312.429)" />
            </g>
        </svg>
    </PrimaryButton>
)).withConfig(dontForward("dir"))<IArrowButtonProps>(({ theme, dir }) => ({
    position: "absolute",
    top: "50%",
    left: dir === "left" ? 0 : undefined,
    right: dir === "right" ? 0 : undefined,
    transform: "translateY(-50%)",
    paddingTop: theme.baseUnit * 2,
    paddingBottom: theme.baseUnit * 2,
    "& > svg": {
        height: theme.baseUnit * 3,
        transform: dir === "left" ? "scaleX(-1)" : ""
    }
}));

interface IScrollSliderProps {
    scrollToItemIndex?: number;
}

const ScrollSlider: React.FC<IScrollSliderProps> = ({ children, scrollToItemIndex }) => {

    const innerContainerRef = useRef<HTMLDivElement>(null);

    const [scrollInfo, setScrollInfo] = useState<IScrollInfo>(getScrollInfo(innerContainerRef.current));

    const onScroll = (event: Event) => {
        if (event.currentTarget instanceof HTMLDivElement) {
            setScrollInfo(getScrollInfo(event.currentTarget));
        }
    };

    useEffect(() => {

        setScrollInfo(getScrollInfo(innerContainerRef.current));

        innerContainerRef.current?.addEventListener("scroll", onScroll);
    
        return () => {
            innerContainerRef.current?.removeEventListener("scroll", onScroll);
        };

    }, [innerContainerRef]);

    useEffect(() => {

        if (innerContainerRef.current && scrollToItemIndex !== undefined) {

            const element = innerContainerRef.current.children.item(scrollToItemIndex);
            if (element instanceof HTMLElement) {
                scrollIntoView(innerContainerRef.current, element);
            }
        }

    }, [scrollToItemIndex, innerContainerRef]);

    useMutationObserver(innerContainerRef, () => {
        setScrollInfo(getScrollInfo(innerContainerRef.current));
    }, { childList: true });

    useOnWindowResize(() => {
        setScrollInfo(getScrollInfo(innerContainerRef.current));
    });

    const onInnerContainerClick = (event: React.MouseEvent<HTMLElement>) => {

        const containerElement = innerContainerRef.current;

        if (!containerElement || scrollToItemIndex !== undefined) {
            return null;
        }

        const clickedChild = Array.from(containerElement.children)
            .find(c => c === event.target || c.contains(event.target as Node));

        if (clickedChild instanceof HTMLElement) {
            scrollIntoView(containerElement, clickedChild);
        }
    };

    return (
        <Container>
            <InnerContainer ref={innerContainerRef} onClick={onInnerContainerClick}>
                {children}
            </InnerContainer>
            {scrollInfo.canScrollLeft && (
                <ArrowButton dir="left" onClick={() => scrollByThirdWidth(innerContainerRef.current, false)} />
            )}
            {scrollInfo.canScrollRight && (
                <ArrowButton dir="right" onClick={() => scrollByThirdWidth(innerContainerRef.current, true)} />
            )}
        </Container>
    );
};

export default ScrollSlider;