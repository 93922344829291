import styled from "styled-components";

const ArticleName = styled("h3")(({ theme }) => ({
    fontFamily: theme.typography.families.headline,
    fontWeight: theme.typography.bold,
    fontSize: 20,
    lineHeight: "1.1",
    letterSpacing: "0.025em",
    marginBottom: "0.25em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.media("sm")]: {
        fontSize: 22
    }
}));

export default ArticleName;