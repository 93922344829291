import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
    DateTime: string;
};

export type Article = {
    __typename?: "Article";
    PreviewHasVariants: Scalars["Boolean"];
    PreviewVariants: Array<Article>;
    attributes: Array<Scalars["String"]>;
    baseUnitPrice?: Maybe<Scalars["String"]>;
    baseUnitPriceSammel?: Maybe<Scalars["String"]>;
    categoryId: Scalars["String"];
    collectionId: Scalars["String"];
    deCardPoints: Scalars["Int"];
    descriptionLines: Array<Scalars["String"]>;
    hasVariants: Scalars["Boolean"];
    hideNormalPrice: Scalars["Boolean"];
    id: Scalars["ID"];
    imageUrl?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    normalPrice?: Maybe<Scalars["Float"]>;
    offeredPrice: Scalars["Float"];
    special?: Maybe<Special>;
    variants: Array<Article>;
};


export type ArticlePreviewHasVariantsArgs = {
    atDate: Scalars["DateTime"];
};


export type ArticlePreviewVariantsArgs = {
    atDate: Scalars["DateTime"];
};

export type BusinessHours = {
    __typename?: "BusinessHours";
    day: Scalars["Int"];
    id: Scalars["ID"];
    open: Scalars["Boolean"];
    openFrom?: Maybe<Scalars["String"]>;
    openTo?: Maybe<Scalars["String"]>;
};

export type Filter = {
    __typename?: "Filter";
    iconUrl?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    label: Scalars["String"];
};

export type Handout = {
    __typename?: "Handout";
    article?: Maybe<Article>;
    articles: Array<Article>;
    articlesByIds: Array<Article>;
    articlesCount: Scalars["Int"];
    availableFilters: Array<Scalars["String"]>;
    categories: Array<HandoutCategory>;
    category?: Maybe<HandoutCategory>;
    documentUrl?: Maybe<Scalars["String"]>;
    end: Scalars["DateTime"];
    filteredCount: Scalars["Int"];
    footerDisableSubscribe?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    marketNamAndSelect?: Maybe<Scalars["String"]>;
    start: Scalars["DateTime"];
    title?: Maybe<Scalars["String"]>;
    uniqueArticles: Array<Article>;
    uniqueArticlesCount: Scalars["Int"];
    uniqueArticlesCountAll: Scalars["Int"];
    uniqueFilteredCount: Scalars["Int"];
};


export type HandoutArticleArgs = {
    id: Scalars["String"];
};


export type HandoutArticlesArgs = {
    filters?: InputMaybe<Array<Scalars["String"]>>;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
};


export type HandoutArticlesByIdsArgs = {
    ids: Array<Scalars["String"]>;
};


export type HandoutCategoryArgs = {
    id: Scalars["String"];
};


export type HandoutFilteredCountArgs = {
    filters?: InputMaybe<Array<Scalars["String"]>>;
};


export type HandoutUniqueArticlesArgs = {
    filters?: InputMaybe<Array<Scalars["String"]>>;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
};


export type HandoutUniqueFilteredCountArgs = {
    filters?: InputMaybe<Array<Scalars["String"]>>;
};

export type HandoutCategory = {
    __typename?: "HandoutCategory";
    articles: Array<Article>;
    articlesCount: Scalars["Int"];
    availableFilters: Array<Scalars["String"]>;
    filteredCount: Scalars["Int"];
    id: Scalars["ID"];
    name: Scalars["String"];
    uniqueArticles: Array<Article>;
    uniqueArticlesCount: Scalars["Int"];
    uniqueFilteredCount: Scalars["Int"];
    uniqueFilteredCountAll: Scalars["Int"];
};


export type HandoutCategoryArticlesArgs = {
    filters?: InputMaybe<Array<Scalars["String"]>>;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
};


export type HandoutCategoryFilteredCountArgs = {
    filters?: InputMaybe<Array<Scalars["String"]>>;
};


export type HandoutCategoryUniqueArticlesArgs = {
    filters?: InputMaybe<Array<Scalars["String"]>>;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
};


export type HandoutCategoryUniqueFilteredCountArgs = {
    filters?: InputMaybe<Array<Scalars["String"]>>;
};


export type HandoutCategoryUniqueFilteredCountAllArgs = {
    filters?: InputMaybe<Array<Scalars["String"]>>;
};

export type MarketInfo = {
    __typename?: "MarketInfo";
    address: Array<Scalars["String"]>;
    businessHours: Array<BusinessHours>;
    facebookLink?: Maybe<Scalars["String"]>;
    googleMapsLink?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    name: Scalars["String"];
    nameShort: Scalars["String"];
};

export type Mutation = {
    __typename?: "Mutation";
    subscribeNewsletter: SubscribeResult;
};


export type MutationSubscribeNewsletterArgs = {
    input: SubscribeInput;
};

export type Query = {
    __typename?: "Query";
    currentHandout?: Maybe<Handout>;
    filters: Array<Filter>;
    handout?: Maybe<Handout>;
    markets: Array<MarketInfo>;
};


export type QueryHandoutArgs = {
    atDate: Scalars["DateTime"];
};

/** marketing special */
export enum Special {
    BigHit = "bigHit",
    MondayBigHit = "mondayBigHit",
    OnlyNormal = "onlyNormal",
    SaturdayBigHit = "saturdayBigHit",
    ThreeDaysPrice = "threeDaysPrice"
}

export type SubscribeInput = {
    recaptcha?: InputMaybe<Scalars["String"]>;
    u_EMail?: InputMaybe<Scalars["String"]>;
    u_FirstName?: InputMaybe<Scalars["String"]>;
    u_LastName?: InputMaybe<Scalars["String"]>;
    u_PrivateCity?: InputMaybe<Scalars["String"]>;
    u_PrivateStreet?: InputMaybe<Scalars["String"]>;
    u_PrivateZIPCode?: InputMaybe<Scalars["String"]>;
    u_Salutation?: InputMaybe<Scalars["String"]>;
    u_UserFieldString1?: InputMaybe<Scalars["String"]>;
};

export type SubscribeResult = {
    __typename?: "SubscribeResult";
    error?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    success: Scalars["Boolean"];
};

export type AllArticlesQueryVariables = Exact<{
    offset?: InputMaybe<Scalars["Int"]>;
    limit?: InputMaybe<Scalars["Int"]>;
    filters?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;


export type AllArticlesQuery = { __typename?: "Query", currentHandout?: { __typename?: "Handout", id: string, documentUrl?: string | null, uniqueFilteredCount: number, uniqueArticlesCount: number, uniqueArticlesCountAll: number, uniqueArticles: Array<{ __typename?: "Article", hasVariants: boolean, id: string, baseUnitPrice?: string | null, baseUnitPriceSammel?: string | null, special?: Special | null, offeredPrice: number, normalPrice?: number | null, name: string, descriptionLines: Array<string>, imageUrl?: string | null, attributes: Array<string>, hideNormalPrice: boolean, categoryId: string, deCardPoints: number }>, categories: Array<{ __typename?: "HandoutCategory", id: string, name: string }> } | null };

export type ArticleDetailsFragment = { __typename?: "Article", id: string, baseUnitPrice?: string | null, baseUnitPriceSammel?: string | null, special?: Special | null, offeredPrice: number, normalPrice?: number | null, name: string, descriptionLines: Array<string>, imageUrl?: string | null, attributes: Array<string>, hideNormalPrice: boolean, categoryId: string, deCardPoints: number };

export type ArticleDetailsWithVariantsFragment = { __typename?: "Article", hasVariants: boolean, id: string, baseUnitPrice?: string | null, baseUnitPriceSammel?: string | null, special?: Special | null, offeredPrice: number, normalPrice?: number | null, name: string, descriptionLines: Array<string>, imageUrl?: string | null, attributes: Array<string>, hideNormalPrice: boolean, categoryId: string, deCardPoints: number };

export type ArticleVariantsAndFiltersQueryVariables = Exact<{
    id: Scalars["String"];
}>;


export type ArticleVariantsAndFiltersQuery = { __typename?: "Query", currentHandout?: { __typename?: "Handout", article?: { __typename?: "Article", variants: Array<{ __typename?: "Article", id: string, baseUnitPrice?: string | null, baseUnitPriceSammel?: string | null, special?: Special | null, offeredPrice: number, normalPrice?: number | null, name: string, descriptionLines: Array<string>, imageUrl?: string | null, attributes: Array<string>, hideNormalPrice: boolean, categoryId: string, deCardPoints: number }> } | null } | null, filters: Array<{ __typename?: "Filter", id: string, label: string, iconUrl?: string | null }> };

export type ArticlesByIdsQueryVariables = Exact<{
    ids: Array<Scalars["String"]> | Scalars["String"];
}>;


export type ArticlesByIdsQuery = { __typename?: "Query", currentHandout?: { __typename?: "Handout", articlesByIds: Array<{ __typename?: "Article", id: string, baseUnitPrice?: string | null, baseUnitPriceSammel?: string | null, special?: Special | null, offeredPrice: number, normalPrice?: number | null, name: string, descriptionLines: Array<string>, imageUrl?: string | null, attributes: Array<string>, hideNormalPrice: boolean, categoryId: string, deCardPoints: number }> } | null };

export type ArticlesInCategoryAndOtherCountsQueryVariables = Exact<{
    categoryId: Scalars["String"];
    offset?: InputMaybe<Scalars["Int"]>;
    limit?: InputMaybe<Scalars["Int"]>;
    filters?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;


export type ArticlesInCategoryAndOtherCountsQuery = { __typename?: "Query", currentHandout?: { __typename?: "Handout", id: string, documentUrl?: string | null, category?: { __typename?: "HandoutCategory", id: string, name: string, uniqueArticlesCount: number, uniqueFilteredCount: number, uniqueFilteredCountAll: number, availableFilters: Array<string>, uniqueArticles: Array<{ __typename?: "Article", hasVariants: boolean, id: string, baseUnitPrice?: string | null, baseUnitPriceSammel?: string | null, special?: Special | null, offeredPrice: number, normalPrice?: number | null, name: string, descriptionLines: Array<string>, imageUrl?: string | null, attributes: Array<string>, hideNormalPrice: boolean, categoryId: string, deCardPoints: number }> } | null, categories: Array<{ __typename?: "HandoutCategory", id: string, name: string, articlesCount: number }> } | null };

export type BusinessHoursDetailsFragment = { __typename?: "BusinessHours", id: string, day: number, open: boolean, openFrom?: string | null, openTo?: string | null };

export type CategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesQuery = { __typename?: "Query", currentHandout?: { __typename?: "Handout", id: string, documentUrl?: string | null, title?: string | null, categories: Array<{ __typename?: "HandoutCategory", id: string, name: string, articlesCount: number }> } | null };

export type CategoryDetailsFragment = { __typename?: "HandoutCategory", id: string, name: string, articlesCount: number };

export type FiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type FiltersQuery = { __typename?: "Query", filters: Array<{ __typename?: "Filter", id: string, label: string, iconUrl?: string | null }> };

export type HandoutCategoriesStatsAndDocumentUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type HandoutCategoriesStatsAndDocumentUrlQuery = { __typename?: "Query", currentHandout?: { __typename?: "Handout", id: string, start: string, end: string, documentUrl?: string | null, marketNamAndSelect?: string | null, footerDisableSubscribe?: string | null, categories: Array<{ __typename?: "HandoutCategory", id: string, name: string, articlesCount: number }> } | null };

export type MarketInfoDetailsFragment = { __typename?: "MarketInfo", id: string, name: string, nameShort: string, address: Array<string>, facebookLink?: string | null, googleMapsLink?: string | null, businessHours: Array<{ __typename?: "BusinessHours", id: string, day: number, open: boolean, openFrom?: string | null, openTo?: string | null }> };

export type MarketsQueryVariables = Exact<{ [key: string]: never; }>;


export type MarketsQuery = { __typename?: "Query", markets: Array<{ __typename?: "MarketInfo", id: string, name: string, nameShort: string, address: Array<string>, facebookLink?: string | null, googleMapsLink?: string | null, businessHours: Array<{ __typename?: "BusinessHours", id: string, day: number, open: boolean, openFrom?: string | null, openTo?: string | null }> }> };

export type PreviewAllArticlesQueryVariables = Exact<{
    atDate: Scalars["DateTime"];
    offset?: InputMaybe<Scalars["Int"]>;
    limit?: InputMaybe<Scalars["Int"]>;
    filters?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;


export type PreviewAllArticlesQuery = { __typename?: "Query", handout?: { __typename?: "Handout", id: string, documentUrl?: string | null, uniqueFilteredCount: number, uniqueArticlesCount: number, uniqueArticlesCountAll: number, uniqueArticles: Array<{ __typename?: "Article", id: string, baseUnitPrice?: string | null, baseUnitPriceSammel?: string | null, special?: Special | null, offeredPrice: number, normalPrice?: number | null, name: string, descriptionLines: Array<string>, imageUrl?: string | null, attributes: Array<string>, hideNormalPrice: boolean, categoryId: string, deCardPoints: number, hasVariants: boolean }>, categories: Array<{ __typename?: "HandoutCategory", id: string, name: string }> } | null };

export type PreviewArticleDetailsWithVariantsFragment = { __typename?: "Article", id: string, baseUnitPrice?: string | null, baseUnitPriceSammel?: string | null, special?: Special | null, offeredPrice: number, normalPrice?: number | null, name: string, descriptionLines: Array<string>, imageUrl?: string | null, attributes: Array<string>, hideNormalPrice: boolean, categoryId: string, deCardPoints: number, hasVariants: boolean };

export type PreviewArticleVariantsAndFiltersQueryVariables = Exact<{
    atDate: Scalars["DateTime"];
    id: Scalars["String"];
}>;


export type PreviewArticleVariantsAndFiltersQuery = { __typename?: "Query", handout?: { __typename?: "Handout", article?: { __typename?: "Article", variants: Array<{ __typename?: "Article", id: string, baseUnitPrice?: string | null, baseUnitPriceSammel?: string | null, special?: Special | null, offeredPrice: number, normalPrice?: number | null, name: string, descriptionLines: Array<string>, imageUrl?: string | null, attributes: Array<string>, hideNormalPrice: boolean, categoryId: string, deCardPoints: number }> } | null } | null, filters: Array<{ __typename?: "Filter", id: string, label: string, iconUrl?: string | null }> };

export type PreviewArticlesInCategoryAndOtherCountsQueryVariables = Exact<{
    atDate: Scalars["DateTime"];
    categoryId: Scalars["String"];
    offset?: InputMaybe<Scalars["Int"]>;
    limit?: InputMaybe<Scalars["Int"]>;
    filters?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;


export type PreviewArticlesInCategoryAndOtherCountsQuery = { __typename?: "Query", handout?: { __typename?: "Handout", id: string, documentUrl?: string | null, category?: { __typename?: "HandoutCategory", id: string, name: string, uniqueArticlesCount: number, uniqueFilteredCount: number, uniqueFilteredCountAll: number, availableFilters: Array<string>, uniqueArticles: Array<{ __typename?: "Article", id: string, baseUnitPrice?: string | null, baseUnitPriceSammel?: string | null, special?: Special | null, offeredPrice: number, normalPrice?: number | null, name: string, descriptionLines: Array<string>, imageUrl?: string | null, attributes: Array<string>, hideNormalPrice: boolean, categoryId: string, deCardPoints: number, hasVariants: boolean }> } | null, categories: Array<{ __typename?: "HandoutCategory", id: string, name: string, articlesCount: number }> } | null };

export type PreviewCategoriesQueryVariables = Exact<{
    atDate: Scalars["DateTime"];
}>;


export type PreviewCategoriesQuery = { __typename?: "Query", handout?: { __typename?: "Handout", id: string, documentUrl?: string | null, title?: string | null, categories: Array<{ __typename?: "HandoutCategory", id: string, name: string, articlesCount: number }> } | null };

export type PreviewHandoutCategoriesStatsAndDocumentUrlQueryVariables = Exact<{
    atDate: Scalars["DateTime"];
}>;


export type PreviewHandoutCategoriesStatsAndDocumentUrlQuery = { __typename?: "Query", handout?: { __typename?: "Handout", id: string, start: string, end: string, documentUrl?: string | null, marketNamAndSelect?: string | null, footerDisableSubscribe?: string | null, categories: Array<{ __typename?: "HandoutCategory", id: string, name: string, articlesCount: number }> } | null };

export type SubscribeNewsletterMutationVariables = Exact<{
    input: SubscribeInput;
}>;


export type SubscribeNewsletterMutation = { __typename?: "Mutation", subscribeNewsletter: { __typename?: "SubscribeResult", success: boolean, error?: string | null } };

export const ArticleDetailsFragmentDoc = gql`
    fragment ArticleDetails on Article {
  id
  baseUnitPrice
  baseUnitPriceSammel
  special
  offeredPrice
  normalPrice
  name
  descriptionLines
  imageUrl
  attributes
  hideNormalPrice
  categoryId
  deCardPoints
}
    `;
export const ArticleDetailsWithVariantsFragmentDoc = gql`
    fragment ArticleDetailsWithVariants on Article {
  ...ArticleDetails
  hasVariants
}
    ${ArticleDetailsFragmentDoc}`;
export const CategoryDetailsFragmentDoc = gql`
    fragment CategoryDetails on HandoutCategory {
  id
  name
  articlesCount
}
    `;
export const BusinessHoursDetailsFragmentDoc = gql`
    fragment BusinessHoursDetails on BusinessHours {
  id
  day
  open
  openFrom
  openTo
}
    `;
export const MarketInfoDetailsFragmentDoc = gql`
    fragment MarketInfoDetails on MarketInfo {
  id
  name
  nameShort
  address
  businessHours {
    ...BusinessHoursDetails
  }
  facebookLink
  googleMapsLink
}
    ${BusinessHoursDetailsFragmentDoc}`;
export const PreviewArticleDetailsWithVariantsFragmentDoc = gql`
    fragment PreviewArticleDetailsWithVariants on Article {
  ...ArticleDetails
  hasVariants: PreviewHasVariants(atDate: $atDate)
}
    ${ArticleDetailsFragmentDoc}`;
export const AllArticlesDocument = gql`
    query AllArticles($offset: Int, $limit: Int, $filters: [String!]) {
  currentHandout {
    id
    documentUrl
    uniqueArticles(offset: $offset, limit: $limit, filters: $filters) {
      ...ArticleDetailsWithVariants
    }
    uniqueFilteredCount(filters: $filters)
    uniqueArticlesCount
    uniqueArticlesCountAll
    categories {
      id
      name
    }
  }
}
    ${ArticleDetailsWithVariantsFragmentDoc}`;

/**
 * __useAllArticlesQuery__
 *
 * To run a query within a React component, call `useAllArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllArticlesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAllArticlesQuery(baseOptions?: Apollo.QueryHookOptions<AllArticlesQuery, AllArticlesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AllArticlesQuery, AllArticlesQueryVariables>(AllArticlesDocument, options);
}
export function useAllArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllArticlesQuery, AllArticlesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AllArticlesQuery, AllArticlesQueryVariables>(AllArticlesDocument, options);
}
export type AllArticlesQueryHookResult = ReturnType<typeof useAllArticlesQuery>;
export type AllArticlesLazyQueryHookResult = ReturnType<typeof useAllArticlesLazyQuery>;
export type AllArticlesQueryResult = Apollo.QueryResult<AllArticlesQuery, AllArticlesQueryVariables>;
export const ArticleVariantsAndFiltersDocument = gql`
    query ArticleVariantsAndFilters($id: String!) {
  currentHandout {
    article(id: $id) {
      variants {
        ...ArticleDetails
      }
    }
  }
  filters {
    id
    label
    iconUrl
  }
}
    ${ArticleDetailsFragmentDoc}`;

/**
 * __useArticleVariantsAndFiltersQuery__
 *
 * To run a query within a React component, call `useArticleVariantsAndFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleVariantsAndFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleVariantsAndFiltersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleVariantsAndFiltersQuery(baseOptions: Apollo.QueryHookOptions<ArticleVariantsAndFiltersQuery, ArticleVariantsAndFiltersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ArticleVariantsAndFiltersQuery, ArticleVariantsAndFiltersQueryVariables>(ArticleVariantsAndFiltersDocument, options);
}
export function useArticleVariantsAndFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleVariantsAndFiltersQuery, ArticleVariantsAndFiltersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ArticleVariantsAndFiltersQuery, ArticleVariantsAndFiltersQueryVariables>(ArticleVariantsAndFiltersDocument, options);
}
export type ArticleVariantsAndFiltersQueryHookResult = ReturnType<typeof useArticleVariantsAndFiltersQuery>;
export type ArticleVariantsAndFiltersLazyQueryHookResult = ReturnType<typeof useArticleVariantsAndFiltersLazyQuery>;
export type ArticleVariantsAndFiltersQueryResult = Apollo.QueryResult<ArticleVariantsAndFiltersQuery, ArticleVariantsAndFiltersQueryVariables>;
export const ArticlesByIdsDocument = gql`
    query ArticlesByIds($ids: [String!]!) {
  currentHandout {
    articlesByIds(ids: $ids) {
      ...ArticleDetails
    }
  }
}
    ${ArticleDetailsFragmentDoc}`;

/**
 * __useArticlesByIdsQuery__
 *
 * To run a query within a React component, call `useArticlesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArticlesByIdsQuery(baseOptions: Apollo.QueryHookOptions<ArticlesByIdsQuery, ArticlesByIdsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ArticlesByIdsQuery, ArticlesByIdsQueryVariables>(ArticlesByIdsDocument, options);
}
export function useArticlesByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticlesByIdsQuery, ArticlesByIdsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ArticlesByIdsQuery, ArticlesByIdsQueryVariables>(ArticlesByIdsDocument, options);
}
export type ArticlesByIdsQueryHookResult = ReturnType<typeof useArticlesByIdsQuery>;
export type ArticlesByIdsLazyQueryHookResult = ReturnType<typeof useArticlesByIdsLazyQuery>;
export type ArticlesByIdsQueryResult = Apollo.QueryResult<ArticlesByIdsQuery, ArticlesByIdsQueryVariables>;
export const ArticlesInCategoryAndOtherCountsDocument = gql`
    query ArticlesInCategoryAndOtherCounts($categoryId: String!, $offset: Int, $limit: Int, $filters: [String!]) {
  currentHandout {
    id
    documentUrl
    category(id: $categoryId) {
      id
      name
      uniqueArticles(offset: $offset, limit: $limit, filters: $filters) {
        ...ArticleDetailsWithVariants
      }
      uniqueArticlesCount
      uniqueFilteredCount(filters: $filters)
      uniqueFilteredCountAll(filters: $filters)
      availableFilters
    }
    categories {
      id
      name
      articlesCount
    }
  }
}
    ${ArticleDetailsWithVariantsFragmentDoc}`;

/**
 * __useArticlesInCategoryAndOtherCountsQuery__
 *
 * To run a query within a React component, call `useArticlesInCategoryAndOtherCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesInCategoryAndOtherCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesInCategoryAndOtherCountsQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useArticlesInCategoryAndOtherCountsQuery(baseOptions: Apollo.QueryHookOptions<ArticlesInCategoryAndOtherCountsQuery, ArticlesInCategoryAndOtherCountsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ArticlesInCategoryAndOtherCountsQuery, ArticlesInCategoryAndOtherCountsQueryVariables>(ArticlesInCategoryAndOtherCountsDocument, options);
}
export function useArticlesInCategoryAndOtherCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticlesInCategoryAndOtherCountsQuery, ArticlesInCategoryAndOtherCountsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ArticlesInCategoryAndOtherCountsQuery, ArticlesInCategoryAndOtherCountsQueryVariables>(ArticlesInCategoryAndOtherCountsDocument, options);
}
export type ArticlesInCategoryAndOtherCountsQueryHookResult = ReturnType<typeof useArticlesInCategoryAndOtherCountsQuery>;
export type ArticlesInCategoryAndOtherCountsLazyQueryHookResult = ReturnType<typeof useArticlesInCategoryAndOtherCountsLazyQuery>;
export type ArticlesInCategoryAndOtherCountsQueryResult = Apollo.QueryResult<ArticlesInCategoryAndOtherCountsQuery, ArticlesInCategoryAndOtherCountsQueryVariables>;
export const CategoriesDocument = gql`
    query Categories {
  currentHandout {
    id
    documentUrl
    title
    categories {
      ...CategoryDetails
    }
  }
}
    ${CategoryDetailsFragmentDoc}`;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
}
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
}
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const FiltersDocument = gql`
    query Filters {
  filters {
    id
    label
    iconUrl
  }
}
    `;

/**
 * __useFiltersQuery__
 *
 * To run a query within a React component, call `useFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFiltersQuery(baseOptions?: Apollo.QueryHookOptions<FiltersQuery, FiltersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FiltersQuery, FiltersQueryVariables>(FiltersDocument, options);
}
export function useFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FiltersQuery, FiltersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FiltersQuery, FiltersQueryVariables>(FiltersDocument, options);
}
export type FiltersQueryHookResult = ReturnType<typeof useFiltersQuery>;
export type FiltersLazyQueryHookResult = ReturnType<typeof useFiltersLazyQuery>;
export type FiltersQueryResult = Apollo.QueryResult<FiltersQuery, FiltersQueryVariables>;
export const HandoutCategoriesStatsAndDocumentUrlDocument = gql`
    query HandoutCategoriesStatsAndDocumentUrl {
  currentHandout {
    id
    start
    end
    documentUrl
    marketNamAndSelect
    footerDisableSubscribe
    categories {
      id
      name
      articlesCount
    }
  }
}
    `;

/**
 * __useHandoutCategoriesStatsAndDocumentUrlQuery__
 *
 * To run a query within a React component, call `useHandoutCategoriesStatsAndDocumentUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useHandoutCategoriesStatsAndDocumentUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHandoutCategoriesStatsAndDocumentUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useHandoutCategoriesStatsAndDocumentUrlQuery(baseOptions?: Apollo.QueryHookOptions<HandoutCategoriesStatsAndDocumentUrlQuery, HandoutCategoriesStatsAndDocumentUrlQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<HandoutCategoriesStatsAndDocumentUrlQuery, HandoutCategoriesStatsAndDocumentUrlQueryVariables>(HandoutCategoriesStatsAndDocumentUrlDocument, options);
}
export function useHandoutCategoriesStatsAndDocumentUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HandoutCategoriesStatsAndDocumentUrlQuery, HandoutCategoriesStatsAndDocumentUrlQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<HandoutCategoriesStatsAndDocumentUrlQuery, HandoutCategoriesStatsAndDocumentUrlQueryVariables>(HandoutCategoriesStatsAndDocumentUrlDocument, options);
}
export type HandoutCategoriesStatsAndDocumentUrlQueryHookResult = ReturnType<typeof useHandoutCategoriesStatsAndDocumentUrlQuery>;
export type HandoutCategoriesStatsAndDocumentUrlLazyQueryHookResult = ReturnType<typeof useHandoutCategoriesStatsAndDocumentUrlLazyQuery>;
export type HandoutCategoriesStatsAndDocumentUrlQueryResult = Apollo.QueryResult<HandoutCategoriesStatsAndDocumentUrlQuery, HandoutCategoriesStatsAndDocumentUrlQueryVariables>;
export const MarketsDocument = gql`
    query Markets {
  markets {
    ...MarketInfoDetails
  }
}
    ${MarketInfoDetailsFragmentDoc}`;

/**
 * __useMarketsQuery__
 *
 * To run a query within a React component, call `useMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketsQuery(baseOptions?: Apollo.QueryHookOptions<MarketsQuery, MarketsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MarketsQuery, MarketsQueryVariables>(MarketsDocument, options);
}
export function useMarketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketsQuery, MarketsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MarketsQuery, MarketsQueryVariables>(MarketsDocument, options);
}
export type MarketsQueryHookResult = ReturnType<typeof useMarketsQuery>;
export type MarketsLazyQueryHookResult = ReturnType<typeof useMarketsLazyQuery>;
export type MarketsQueryResult = Apollo.QueryResult<MarketsQuery, MarketsQueryVariables>;
export const PreviewAllArticlesDocument = gql`
    query PreviewAllArticles($atDate: DateTime!, $offset: Int, $limit: Int, $filters: [String!]) {
  handout(atDate: $atDate) {
    id
    documentUrl
    uniqueArticles(offset: $offset, limit: $limit, filters: $filters) {
      ...PreviewArticleDetailsWithVariants
    }
    uniqueFilteredCount(filters: $filters)
    uniqueArticlesCount
    uniqueArticlesCountAll
    categories {
      id
      name
    }
  }
}
    ${PreviewArticleDetailsWithVariantsFragmentDoc}`;

/**
 * __usePreviewAllArticlesQuery__
 *
 * To run a query within a React component, call `usePreviewAllArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewAllArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewAllArticlesQuery({
 *   variables: {
 *      atDate: // value for 'atDate'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePreviewAllArticlesQuery(baseOptions: Apollo.QueryHookOptions<PreviewAllArticlesQuery, PreviewAllArticlesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PreviewAllArticlesQuery, PreviewAllArticlesQueryVariables>(PreviewAllArticlesDocument, options);
}
export function usePreviewAllArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewAllArticlesQuery, PreviewAllArticlesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PreviewAllArticlesQuery, PreviewAllArticlesQueryVariables>(PreviewAllArticlesDocument, options);
}
export type PreviewAllArticlesQueryHookResult = ReturnType<typeof usePreviewAllArticlesQuery>;
export type PreviewAllArticlesLazyQueryHookResult = ReturnType<typeof usePreviewAllArticlesLazyQuery>;
export type PreviewAllArticlesQueryResult = Apollo.QueryResult<PreviewAllArticlesQuery, PreviewAllArticlesQueryVariables>;
export const PreviewArticleVariantsAndFiltersDocument = gql`
    query PreviewArticleVariantsAndFilters($atDate: DateTime!, $id: String!) {
  handout(atDate: $atDate) {
    article(id: $id) {
      variants: PreviewVariants(atDate: $atDate) {
        ...ArticleDetails
      }
    }
  }
  filters {
    id
    label
    iconUrl
  }
}
    ${ArticleDetailsFragmentDoc}`;

/**
 * __usePreviewArticleVariantsAndFiltersQuery__
 *
 * To run a query within a React component, call `usePreviewArticleVariantsAndFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewArticleVariantsAndFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewArticleVariantsAndFiltersQuery({
 *   variables: {
 *      atDate: // value for 'atDate'
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePreviewArticleVariantsAndFiltersQuery(baseOptions: Apollo.QueryHookOptions<PreviewArticleVariantsAndFiltersQuery, PreviewArticleVariantsAndFiltersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PreviewArticleVariantsAndFiltersQuery, PreviewArticleVariantsAndFiltersQueryVariables>(PreviewArticleVariantsAndFiltersDocument, options);
}
export function usePreviewArticleVariantsAndFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewArticleVariantsAndFiltersQuery, PreviewArticleVariantsAndFiltersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PreviewArticleVariantsAndFiltersQuery, PreviewArticleVariantsAndFiltersQueryVariables>(PreviewArticleVariantsAndFiltersDocument, options);
}
export type PreviewArticleVariantsAndFiltersQueryHookResult = ReturnType<typeof usePreviewArticleVariantsAndFiltersQuery>;
export type PreviewArticleVariantsAndFiltersLazyQueryHookResult = ReturnType<typeof usePreviewArticleVariantsAndFiltersLazyQuery>;
export type PreviewArticleVariantsAndFiltersQueryResult = Apollo.QueryResult<PreviewArticleVariantsAndFiltersQuery, PreviewArticleVariantsAndFiltersQueryVariables>;
export const PreviewArticlesInCategoryAndOtherCountsDocument = gql`
    query PreviewArticlesInCategoryAndOtherCounts($atDate: DateTime!, $categoryId: String!, $offset: Int, $limit: Int, $filters: [String!]) {
  handout(atDate: $atDate) {
    id
    documentUrl
    category(id: $categoryId) {
      id
      name
      uniqueArticles(offset: $offset, limit: $limit, filters: $filters) {
        ...PreviewArticleDetailsWithVariants
      }
      uniqueArticlesCount
      uniqueFilteredCount(filters: $filters)
      uniqueFilteredCountAll(filters: $filters)
      availableFilters
    }
    categories {
      id
      name
      articlesCount
    }
  }
}
    ${PreviewArticleDetailsWithVariantsFragmentDoc}`;

/**
 * __usePreviewArticlesInCategoryAndOtherCountsQuery__
 *
 * To run a query within a React component, call `usePreviewArticlesInCategoryAndOtherCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewArticlesInCategoryAndOtherCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewArticlesInCategoryAndOtherCountsQuery({
 *   variables: {
 *      atDate: // value for 'atDate'
 *      categoryId: // value for 'categoryId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePreviewArticlesInCategoryAndOtherCountsQuery(baseOptions: Apollo.QueryHookOptions<PreviewArticlesInCategoryAndOtherCountsQuery, PreviewArticlesInCategoryAndOtherCountsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PreviewArticlesInCategoryAndOtherCountsQuery, PreviewArticlesInCategoryAndOtherCountsQueryVariables>(PreviewArticlesInCategoryAndOtherCountsDocument, options);
}
export function usePreviewArticlesInCategoryAndOtherCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewArticlesInCategoryAndOtherCountsQuery, PreviewArticlesInCategoryAndOtherCountsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PreviewArticlesInCategoryAndOtherCountsQuery, PreviewArticlesInCategoryAndOtherCountsQueryVariables>(PreviewArticlesInCategoryAndOtherCountsDocument, options);
}
export type PreviewArticlesInCategoryAndOtherCountsQueryHookResult = ReturnType<typeof usePreviewArticlesInCategoryAndOtherCountsQuery>;
export type PreviewArticlesInCategoryAndOtherCountsLazyQueryHookResult = ReturnType<typeof usePreviewArticlesInCategoryAndOtherCountsLazyQuery>;
export type PreviewArticlesInCategoryAndOtherCountsQueryResult = Apollo.QueryResult<PreviewArticlesInCategoryAndOtherCountsQuery, PreviewArticlesInCategoryAndOtherCountsQueryVariables>;
export const PreviewCategoriesDocument = gql`
    query PreviewCategories($atDate: DateTime!) {
  handout(atDate: $atDate) {
    id
    documentUrl
    title
    categories {
      ...CategoryDetails
    }
  }
}
    ${CategoryDetailsFragmentDoc}`;

/**
 * __usePreviewCategoriesQuery__
 *
 * To run a query within a React component, call `usePreviewCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewCategoriesQuery({
 *   variables: {
 *      atDate: // value for 'atDate'
 *   },
 * });
 */
export function usePreviewCategoriesQuery(baseOptions: Apollo.QueryHookOptions<PreviewCategoriesQuery, PreviewCategoriesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PreviewCategoriesQuery, PreviewCategoriesQueryVariables>(PreviewCategoriesDocument, options);
}
export function usePreviewCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewCategoriesQuery, PreviewCategoriesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PreviewCategoriesQuery, PreviewCategoriesQueryVariables>(PreviewCategoriesDocument, options);
}
export type PreviewCategoriesQueryHookResult = ReturnType<typeof usePreviewCategoriesQuery>;
export type PreviewCategoriesLazyQueryHookResult = ReturnType<typeof usePreviewCategoriesLazyQuery>;
export type PreviewCategoriesQueryResult = Apollo.QueryResult<PreviewCategoriesQuery, PreviewCategoriesQueryVariables>;
export const PreviewHandoutCategoriesStatsAndDocumentUrlDocument = gql`
    query PreviewHandoutCategoriesStatsAndDocumentUrl($atDate: DateTime!) {
  handout(atDate: $atDate) {
    id
    start
    end
    documentUrl
    marketNamAndSelect
    footerDisableSubscribe
    categories {
      id
      name
      articlesCount
    }
  }
}
    `;

/**
 * __usePreviewHandoutCategoriesStatsAndDocumentUrlQuery__
 *
 * To run a query within a React component, call `usePreviewHandoutCategoriesStatsAndDocumentUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewHandoutCategoriesStatsAndDocumentUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewHandoutCategoriesStatsAndDocumentUrlQuery({
 *   variables: {
 *      atDate: // value for 'atDate'
 *   },
 * });
 */
export function usePreviewHandoutCategoriesStatsAndDocumentUrlQuery(baseOptions: Apollo.QueryHookOptions<PreviewHandoutCategoriesStatsAndDocumentUrlQuery, PreviewHandoutCategoriesStatsAndDocumentUrlQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PreviewHandoutCategoriesStatsAndDocumentUrlQuery, PreviewHandoutCategoriesStatsAndDocumentUrlQueryVariables>(PreviewHandoutCategoriesStatsAndDocumentUrlDocument, options);
}
export function usePreviewHandoutCategoriesStatsAndDocumentUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewHandoutCategoriesStatsAndDocumentUrlQuery, PreviewHandoutCategoriesStatsAndDocumentUrlQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PreviewHandoutCategoriesStatsAndDocumentUrlQuery, PreviewHandoutCategoriesStatsAndDocumentUrlQueryVariables>(PreviewHandoutCategoriesStatsAndDocumentUrlDocument, options);
}
export type PreviewHandoutCategoriesStatsAndDocumentUrlQueryHookResult = ReturnType<typeof usePreviewHandoutCategoriesStatsAndDocumentUrlQuery>;
export type PreviewHandoutCategoriesStatsAndDocumentUrlLazyQueryHookResult = ReturnType<typeof usePreviewHandoutCategoriesStatsAndDocumentUrlLazyQuery>;
export type PreviewHandoutCategoriesStatsAndDocumentUrlQueryResult = Apollo.QueryResult<PreviewHandoutCategoriesStatsAndDocumentUrlQuery, PreviewHandoutCategoriesStatsAndDocumentUrlQueryVariables>;
export const SubscribeNewsletterDocument = gql`
    mutation SubscribeNewsletter($input: SubscribeInput!) {
  subscribeNewsletter(input: $input) {
    success
    error
  }
}
    `;
export type SubscribeNewsletterMutationFn = Apollo.MutationFunction<SubscribeNewsletterMutation, SubscribeNewsletterMutationVariables>;

/**
 * __useSubscribeNewsletterMutation__
 *
 * To run a mutation, you first call `useSubscribeNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeNewsletterMutation, { data, loading, error }] = useSubscribeNewsletterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeNewsletterMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeNewsletterMutation, SubscribeNewsletterMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SubscribeNewsletterMutation, SubscribeNewsletterMutationVariables>(SubscribeNewsletterDocument, options);
}
export type SubscribeNewsletterMutationHookResult = ReturnType<typeof useSubscribeNewsletterMutation>;
export type SubscribeNewsletterMutationResult = Apollo.MutationResult<SubscribeNewsletterMutation>;
export type SubscribeNewsletterMutationOptions = Apollo.BaseMutationOptions<SubscribeNewsletterMutation, SubscribeNewsletterMutationVariables>;