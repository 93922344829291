import React, { useContext } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import CheckmarkIcon from "../common/icons/CheckmarkIcon";
import CategorySeenContext from "../contexts/CategorySeenContext";
import dontForward from "../utils/dontForward";
import valuesCss from "../utils/valuesCss";

const CategoryLink = styled(Link).withConfig(dontForward("active"))<{ active: boolean }>(({ theme, active }) => ({
    display: "block",
    fontSize: 24,
    lineHeight: 1.2,
    textDecoration: "none",
    fontFamily: theme.typography.families.headline,
    fontWeight: theme.typography.bold,
    letterSpacing: "0.02em",
    padding: valuesCss("px", theme.baseUnit, theme.baseUnit * 2),
    ...(active && {
        color: theme.palette.drawer.main,
        background: theme.palette.secondary.main
    })
}));

const CategoryCheckmark = styled(CheckmarkIcon)({
    height: "0.4em",
    verticalAlign: "middle",
    marginRight: "0.4em"
});

interface ICategoryItemProps {
    to: string;
    seen: boolean;
    onClick: () => void;
}

const CategoryItem: React.FC<ICategoryItemProps> = ({ to, seen, onClick, children }) => {

    const theme = useTheme();

    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end: true });
    const active = !!match;

    return (
        <CategoryLink to={to} active={active} onClick={onClick}>
            {seen && (
                <CategoryCheckmark color={active ? theme.palette.tertiary.main : "currentColor"} />
            )}
            {children}
        </CategoryLink>
    );
};

interface ISideMenuCategoriesProps {
    categories: Array<{ id: string, name: string, articlesCount: number }>;
    onItemClick: () => void;
    handoutId: string;
}

const SideMenuCategories: React.FC<ISideMenuCategoriesProps> = ({ categories, onItemClick, handoutId }) => {

    const categorySeenContext = useContext(CategorySeenContext);

    return (
        <nav>
            {categories.map(({ id, name, articlesCount }) => (
                <CategoryItem
                    key={id}
                    to={`handout/${id}`}
                    seen={categorySeenContext.hadSeenCategory(handoutId, id)}
                    onClick={onItemClick}
                >
                    {name}{"\u2002"}({articlesCount})
                </CategoryItem>
            ))}
            {categories.length === 0 && (
                <CategoryLink to="handout/back" active={false} onClick={onItemClick}>
                    Momentan keine Angebote
                </CategoryLink>
            )}
        </nav>
    );
};

export default SideMenuCategories;