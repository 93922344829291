import React from "react";
import styled from "styled-components";
import ArrowUpIcon from "../../common/icons/ArrowUp";
import dontForward from "../../utils/dontForward";
import flexCss from "../../utils/flexCss";

const ScrollLiftButton = styled("a").withConfig(dontForward("show"))<{ show: boolean }>(({ theme, show }) => ({
    transition: "transform 500ms ease-in, opacity 500ms ease-in",
    opacity: show ? 1 : 0,
    transform: show ? undefined : "translateY(150%)",
    display: "block",
    cursor: "pointer",
    position: "fixed",
    bottom: theme.layout.footerHeight + (theme.baseUnit * 3),
    left: theme.baseUnit * 2,
    width: theme.baseUnit * 5,
    height: theme.baseUnit * 5,
    background: theme.palette.paper.main,
    borderRadius: "50%",
    boxShadow: "2px 6px 16px rgba(0,0,0,0.3)",
    zIndex: theme.zIndex.flying,
    ...flexCss("v", "center", "center"),
    "& > svg": {
        height: theme.baseUnit * 2
    }
}));

const ScrollLift: React.FC<{ show: boolean }> = ({ show }) => {

    const onClick = () => document.scrollingElement?.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    return (
        <ScrollLiftButton show={show} onClick={onClick}>
            <ArrowUpIcon />
        </ScrollLiftButton>
    );
};

export default ScrollLift;