import React, { forwardRef, useState } from "react";

const PossibleImage = forwardRef<HTMLImageElement, React.ImgHTMLAttributes<HTMLImageElement> & { fallback?: string }>(({ fallback, ...props }, ref) => {

    const [failed, setFailed] = useState(false);

    if (failed && !fallback) {

        return <></>;
    }

    return (
        <img
            {...props}
            ref={ref}
            src={failed ? fallback : (props.src || fallback)}
            onError={!failed ? () => setFailed(true) : undefined}
        />
    );
});

export default PossibleImage;