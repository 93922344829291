import React, { useContext, useEffect, useRef, useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { useMediaMatch } from "rooks";
import { useTheme } from "styled-components";
import CategorySeenContext from "../../../contexts/CategorySeenContext";
import FiltersContext from "../../../contexts/FiltersContext";
import TrackingContext from "../../../contexts/TrackingContext";
import OverlayContext from "../../../contexts/OverlayContext";
import CurrentHandoutContext from "../../../contexts/CurrentHandoutContext";
import {
    ArticleDetailsWithVariantsFragment,
    useArticlesInCategoryAndOtherCountsQuery,
    usePreviewArticlesInCategoryAndOtherCountsQuery
} from "../../../generated/graphql";
import useScrollInfo from "../../../hooks/useScrollInfo";
import LoadingOverlay from "../../../common/LoadingOverlay";
import PrimaryButton from "../../../style/PrimaryButton";
import MoreArticlesButton from "../MoreArticlesButton";
import ScrollLift from "../ScrollLift";
import ArticlesCategory from "../ArticlesCategory";
import CategoryFooter from "./CategoryFooter";
import FilterBar from "./FilterBar";

const Category: React.FC = () => {

    const { category, preview_date } = useParams();
    const location = useLocation();
    const saveCurrentCategory = useRef();
    
    const theme = useTheme();
    const mediaMd = useMediaMatch(theme.breakpoints.md);
    const mediaLg = useMediaMatch(theme.breakpoints.lg);
    const navigate = useNavigate();

    const pageSize = mediaLg ? 12 : mediaMd ? 9 : 6;

    const limit = useRef(pageSize);
    const offset = 0;

    const filtersContext = useContext(FiltersContext);

    const { data: realData, loading: loadingReal, fetchMore: fetchMoreReal } = useArticlesInCategoryAndOtherCountsQuery({
        variables: {
            categoryId: category || "",
            offset,
            limit: pageSize,
            filters: filtersContext.active
        },
        skip: !category || !!preview_date
    });
    const { data: previewData, loading: loadingPreview, fetchMore: fetchMorePreview } = usePreviewArticlesInCategoryAndOtherCountsQuery({
        variables: {
            atDate: preview_date || "",
            categoryId: category || "",
            offset,
            limit: pageSize,
            filters: filtersContext.active
        },
        skip: !category || !preview_date
    });

    const handout = realData?.currentHandout || previewData?.handout;
    const loading = preview_date ? loadingPreview : loadingReal;
    const fetchMore = preview_date ? fetchMorePreview : fetchMoreReal;

    const { openedPopupId } = useContext(OverlayContext);
    const { setCategorySeen } = useContext(CategorySeenContext);
    const currentHandoutContext = useContext(CurrentHandoutContext);

    const { scrolledToBottom, scrolled1Vh } = useScrollInfo(100);
    
    const scrolled = useRef(false);
    const trackingContext = useContext(TrackingContext);

    useEffect(() => {
        if (!scrolled.current && handout && category) {

            scrolled.current = true;

            trackingContext.trackEvent("categoryScrolled", {
                handoutId: handout?.id || "",
                id: category || ""
            });
        }
    }, [scrolled1Vh, handout, category]);

    const currentCategory = handout?.category;

    const totalCount = currentCategory?.uniqueFilteredCountAll || 0;
    const canLoadMore = limit.current < totalCount;
    const canScrollToSeeMore = canLoadMore || !scrolledToBottom;

    const handoutId = handout?.id || "";
    const categoryId = handout?.category?.id || "";

    const onSeenLastOfCategory = () => {

        if (openedPopupId) {
            return;
        }

        const loadedAll = (offset + limit.current) >= totalCount;

        if (loadedAll) {
            setCategorySeen(handout?.id || "", categoryId);

        } else {

            limit.current = Math.min(
                Math.max(
                    limit.current + pageSize,
                    handout?.category?.uniqueArticles.length || 0
                ),
                totalCount || pageSize
            );
    
            fetchMore({
                variables: {
                    limit: limit.current,
                    offset
                }
            });
        }
    };

    const onMoreClick = () => {
        const halfHeight = Math.round(document.body.clientHeight * 0.75);
        document.scrollingElement?.scrollBy({ top: halfHeight, behavior: "smooth" });
    };

    const onArticleClick = (article: ArticleDetailsWithVariantsFragment) => {

        if (article.hasVariants) {
            navigate("variants/" + article.id);
            trackingContext.trackEvent("openVariants", {
                handoutId,
                categoryId: categoryId || undefined,
                articleId: article.id
            });
        }
    };

    useEffect(() => {
        if (handout && currentCategory) {
            const currentHandout = currentHandoutContext.getCurrentHandout(handout?.id);
            if (currentHandout && location?.state?.scroll == true) {
                window.scrollTo(0, currentHandout.scroll);
                limit.current = Math.min(
                    Math.max(
                        limit.current + pageSize,
                        handout?.category?.uniqueArticles.length || 0
                    ),
                    totalCount || pageSize
                );
            } else if (currentCategory?.id !== saveCurrentCategory?.current?.id) {
                saveCurrentCategory.current = currentCategory;    
                limit.current = pageSize;   
                currentHandoutContext.setCurrentHandout(handout?.id, categoryId, "0");
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }
            const onScroll = () => currentHandoutContext.setCurrentHandout(handout?.id, categoryId, window.scrollY);
            window.addEventListener('scroll', onScroll, { passive: true });
            return () => window.removeEventListener('scroll', onScroll);            
        }
    }, [handout]);

    if (!loading && !currentCategory) {

        return(
            <>
                <br />
                <h2>Hier gibt es keine Angebote</h2>
                <PrimaryButton size="large" onClick={() => navigate("../../handout/back")}>zurück zur Startseite</PrimaryButton>
                <Outlet />
            </>
        );
    }

    return (
        <>
            <LoadingOverlay active={loading} />
            {currentCategory && (
                <>
                    <FilterBar
                        availableFilters={currentCategory.availableFilters}
                        allArticlesCount={currentCategory.uniqueArticlesCount}
                        filteredArticlesCount={currentCategory.uniqueFilteredCount}
                        categoryId={currentCategory.id}
                    />
                    <ArticlesCategory
                        categoryId={category || ""}
                        articles={currentCategory.uniqueArticles}
                        filters={filtersContext.all}
                        onSeenLast={onSeenLastOfCategory}
                        onArticleClick={onArticleClick}
                        documentUrl={handout?.documentUrl || null}
                    />
                    <CategoryFooter
                        categoryId={category || ""}
                        categories={handout?.categories || []}
                        allArticlesCount={currentCategory.uniqueArticlesCount}
                        filteredArticlesCount={currentCategory.uniqueFilteredCount}
                        canScrollToSeeMore={canScrollToSeeMore}
                    />
                    {canScrollToSeeMore && (
                        <MoreArticlesButton onClick={onMoreClick}>
                            mehr {currentCategory.name}
                        </MoreArticlesButton>
                    )}
                    <ScrollLift show={scrolled1Vh && !scrolledToBottom} />
                    <Outlet />
                </>
            )}
        </>
    );
};

export default Category;