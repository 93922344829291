import React, { createContext, useEffect, useRef, useState } from "react";
import { useLocalstorageState } from "rooks";
import { useSearchParams } from "react-router-dom";
import { useFiltersQuery } from "../generated/graphql";

export interface IFilter {
    id: string;
    label: string;
    iconUrl?: string | null;
}

export interface IFiltersContext {
    toggle: (id: string) => void;
    disableAll: () => void;
    isActive: (id: string) => boolean;
    active: Array<string>;
    all: Array<IFilter>;
}

const noopHandlers: IFiltersContext = {
    // eslint-disable-next-line
    toggle: (id: string) => {},
    // eslint-disable-next-line
    disableAll: () => {},
    // eslint-disable-next-line
    isActive: (id: string) => false,
    active: [],
    all: []
};

const FiltersContext = createContext<IFiltersContext>(noopHandlers);

export const FiltersProvider: React.FC = ({ children }) => {

    const [filters, setFilters] = useLocalstorageState("filters", new Array<string>());

    const { data } = useFiltersQuery();

    const [searchParams, setSearchParams] = useSearchParams();
    const setInitialFilters = useRef(new Array<string>());

    useEffect(() => {

        const initialFilters = searchParams.getAll("filter");
        if (initialFilters.length) {

            setInitialFilters.current = initialFilters;
            searchParams.delete("filter");
            setSearchParams(searchParams, { replace: true });
        }

    }, [setInitialFilters, searchParams, setSearchParams]);

    useEffect(() => {
        if (setInitialFilters.current.length && data?.filters) {

            setFilters(setInitialFilters.current.filter(f => data.filters.some(({ id }) => id === f)));
            setInitialFilters.current = [];
        }
    }, [data, setFilters, setInitialFilters]);

    const handlers: IFiltersContext = {
        toggle: (id: string) => setFilters(filters.includes(id) ? filters.filter(f => f !== id) : filters.concat(id)),
        disableAll: () => setFilters([]),
        isActive: (id: string) => filters.includes(id),
        active: filters,
        all: data?.filters || []
    };

    return (
        <FiltersContext.Provider value={handlers}>
            {children}
        </FiltersContext.Provider>
    );
};

export default FiltersContext;