import React, { useContext, useMemo, useRef, useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
import { useMediaMatch } from "rooks";
import { useTheme } from "styled-components";
import FiltersContext from "../../../contexts/FiltersContext";
import CurrentHandoutContext from "../../../contexts/CurrentHandoutContext";
import {
    ArticleDetailsWithVariantsFragment,
    useAllArticlesQuery,
    usePreviewAllArticlesQuery
} from "../../../generated/graphql";

const Back: React.FC = () => {

    const { preview_date } = useParams();

    const theme = useTheme();
    const mediaMd = useMediaMatch(theme.breakpoints.md);
    const mediaLg = useMediaMatch(theme.breakpoints.lg);

    const filterContext = useContext(FiltersContext);    
    const navigate = useNavigate();

    const pageSize = mediaLg ? 12 : mediaMd ? 9 : 6;

    const offset = 0;

    const currentHandoutContext = useContext(CurrentHandoutContext);

    const result = useAllArticlesQuery({
        skip: !!preview_date,
        variables: {
            offset,
            limit: pageSize,
            filters: filterContext.active
        }
    });
    const previewResult = usePreviewAllArticlesQuery({
        skip: !preview_date,
        variables: {
            atDate: preview_date || "",
            offset,
            limit: pageSize,
            filters: filterContext.active
        }
    });

    const handout = result.data?.currentHandout || previewResult.data?.handout;

    useEffect(() => {
        if (handout) {
            const currentHandout = currentHandoutContext.getCurrentHandout(handout?.id);
            const scroll = true;
            if (currentHandout && currentHandout?.categoryId !== "")
                navigate("/handout/" + currentHandout?.categoryId, { state: { scroll }});
            else
                navigate("/handout/articles", { state: { scroll }});
        }
    }, [handout]);

    return (
        <>
        </>
    );
};

export default Back;