import React, { useContext } from "react";
import { Link, LinkProps, useMatch, useResolvedPath } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import CatalogIcon from "../common/icons/CatalogIcon";
import FavListIcon from "../common/icons/FavListIcon";
import SubscribeIcon from "../common/icons/Subscribe";
import { SvgIconProps } from "../common/icons/SvgIconProps";
import ShoppingListContext from "../contexts/ShoppingListContext";
import Chalkboard from "../style/Chalkboard";
import dontForward from "../utils/dontForward";
import flexCss from "../utils/flexCss";
import positionCss from "../utils/positionCss";
import valuesCss from "../utils/valuesCss";

const MenuItemLink = styled(Link).withConfig(dontForward("isActive"))<LinkProps & { isActive: boolean }>(({ theme, isActive }) => ({
    ...flexCss("v", "center", "center"),
    minWidth: 100,
    color: isActive ? theme.palette.secondary.main : "currentColor",
    textDecoration: "none",
    fontSize: 13,
    lineHeight: "24px"
}));

interface IMenuItemProps {
    to: string;
    text: string;
    Icon: React.ComponentType<SvgIconProps>;
}

const MenuItem: React.FC<IMenuItemProps> = ({ to, text, Icon, children }) => {
    
    let resolved = useResolvedPath(to);
    if (to == "/handout/back")
      resolved = useResolvedPath("/handout");
    const isActive = !!useMatch({ path: resolved.pathname, end: false });
    const theme = useTheme();

    return (
        <MenuItemLink to={to} isActive={isActive}>
            <div style={{ height: theme.baseUnit * 3, position: "relative" }}>
                <Icon color={isActive ? theme.palette.secondary.main : "currentColor"} style={{ height: "100%" }} />
                {children}
            </div>
            <p>{text}</p>
        </MenuItemLink>
    );
};

const ShoppingListBadge = styled("div")(({ theme }) => ({
    position: "absolute",
    top: -6,
    right: -6,
    zIndex: 1,
    minWidth: theme.baseUnit * 2,
    height: theme.baseUnit * 2,
    borderRadius: theme.baseUnit,
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontSize: 12,
    lineHeight: "16px",
    textAlign: "center"
}));

const FooterContainer = styled(Chalkboard)(({ theme }) => ({
    ...positionCss("fix", "bottom"),
    zIndex: theme.zIndex.bars,
    height: theme.layout.footerHeight
}));

const FooterButtonContainer = styled("div")(({ theme }) => ({
    ...flexCss("h", "space-evenly"),
    padding: valuesCss("px", theme.baseUnit * 2, theme.baseUnit * 8, 0, theme.baseUnit * 8) 
}));

const LegalBox = styled("div")({
    ...flexCss("h", "space-between"),
    lineHeight: "14px",
    fontSize: 11,
    paddingLeft: 10,
    paddingRight: 10,
    color: "#b5b5b5",
    "& > a": {
        textDecoration: "none"
    }
});

interface IFooterProps {
    footerDisableSubscribe: string | null;
}

const Footer: React.FC<IFooterProps> = ({ footerDisableSubscribe }) => {

    const { allItems } = useContext(ShoppingListContext);
    const todoItemsCount = allItems.filter(item => !item.done).length;

    return (
        <FooterContainer as="footer">
            <FooterButtonContainer>
                <MenuItem to="/handout/back" text="Werbung" Icon={CatalogIcon} />
                <MenuItem to="/shopping-list" text="Merkliste" Icon={FavListIcon}>
                    {todoItemsCount > 0 && (
                        <ShoppingListBadge>
                            {todoItemsCount}
                        </ShoppingListBadge>
                    )}
                </MenuItem>
                {!footerDisableSubscribe && (
                    <>
                        <MenuItem to="/subscribe" text="Abonnieren" Icon={SubscribeIcon} />
                    </>
                )}
            </FooterButtonContainer>
            <LegalBox>
                <a href="https://www.stroetmann.de/impressum" target="_blank">Impressum</a>
                <a href="https://www.stroetmann.de/datenschutz" target="_blank">Datenschutz</a>
            </LegalBox>
        </FooterContainer>
    );
};

export default Footer;