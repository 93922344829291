import React from "react";
import { SvgIconProps } from "./SvgIconProps";

const CatalogIcon: React.FC<SvgIconProps> = ({ color, ...props }) => (
    <svg viewBox="0 0 17 21" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinecap="square" strokeLinejoin="round" strokeMiterlimit="3" {...props}>
        <path d="M377.455 924.266H384" fill="none" stroke={color || "currentColor"} strokeWidth="1.2px" transform="translate(-373.33 -912.07)"/>
        <path d="M377.455 924.266H384"  fill="none" stroke={color || "currentColor"} strokeWidth="1.2px" transform="translate(-373.33 -908.573)"/>
        <path d="m388.069 919.88-6.239-5.987h-5.989c-1.132 0-2.049.903-2.049 2.017v14.303c0 1.114.917 2.017 2.049 2.017h10.179c1.132 0 2.049-.903 2.049-2.017V919.88z" fill="none" stroke={color || "currentColor"} strokeWidth="1.2px" strokeLinecap="round" transform="matrix(.96575 0 0 .98133 -360.406 -894.936)"/>
        <path d="M251.239 9.047v6.803h6.96" fill="none" stroke={color || "currentColor"} strokeWidth="1.2px" strokeLinecap="butt" strokeLinejoin="miter" transform="matrix(.86364 0 0 .86364 -208.634 -5.916)"/>
    </svg>
);

export default CatalogIcon;