import { CSSObject } from "styled-components";

export default function flexCss(
    direction: "h" | "v",
    justifyContent?: "space-around" | "space-between" | "space-evenly" | "stretch" | "center" | "end" | "flex-end" | "flex-start" | "start",
    alignItems?: "center" | "flex-end" | "flex-start" | "self-end" | "self-start" | "stretch"
): CSSObject {

    return {
        display: "flex",
        flexDirection: direction === "h" ? "row" : "column",
        justifyContent,
        alignItems
    };
}