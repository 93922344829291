import React, { useEffect } from "react";
import { useNavigate } from "react-router";

const Catchall: React.FC = () => {

    const navigate = useNavigate();
    
    useEffect(() => {
        const timeoutId = setTimeout(() => navigate("/"), 4000);
        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <h2 style={{ margin: 20, textAlign: "center" }}>Die Seite wurde nicht gefunden</h2>
    );
};

export default Catchall;