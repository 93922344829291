import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import flexCss from "../../utils/flexCss";
import { startOfWeek, endOfWeek, getDay, addDays, getYear, getWeek, isValid } from "date-fns";
import Modal from "../../common/Modal";
import OverlayContainer from "../../common/OverlayContainer";
import PossibleImage from "../../common/PossibleImage";
import CloseButton from "../shopping-list/details/CloseButton";
import HandoutImagePopupContext from "../../contexts/HandoutImagePopupContext";

const ModalLayer = styled(OverlayContainer)({
    ...flexCss("v", "end", "flex-end"),
    overflow: "hidden",
    pointerEvents: "none",
    display: "none",
    "& > *": {
        pointerEvents: "auto"
    }
});

const ModalImage = styled(Modal)({
    overflow: "hidden",
    maxWidth: "100%"
});

const ImageProductImage = styled(PossibleImage)({
    padding: "0px",
    width: "100%",
    height: "100%"
});

const CloseButtonPopup = styled(CloseButton)({
    top: 16,
    height: 25,
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0 0 3px 3px rgba(255, 255, 255, 1)"
});

interface IHandoutImagePopupProps {
    documentUrl: string | null;
}

const HandoutImagePopup: React.FC<IHandoutImagePopupProps> = ({ documentUrl }) => {

    const { preview_date } = useParams();

    const [name, setName] = useState("");
    const [target, setTarget] = useState("");

    const handoutImagePopupContext = useContext(HandoutImagePopupContext);

    let now = new Date();

    if (preview_date) {
        const previewDate = new Date(preview_date);
        if (isValid(previewDate)) {
            now = previewDate;
        }
    }

    if (getDay(now) === 0) {
        now = addDays(now, 1); // show next week on sundays
    }

    const from = startOfWeek(now, { weekStartsOn: 1 });
    const to = endOfWeek(now, { weekStartsOn: 1 });
    const fromYear = getYear(from);
    const toYear = getYear(to);
    const fromWeekFormatted = getWeek(from, { weekStartsOn: 1, firstWeekContainsDate: 4 }).toString().padStart(2, 0);

    const handoutImagePopup = handoutImagePopupContext.getHandoutImagePopup(toYear + '_' + fromWeekFormatted);

    useEffect(() => {
        if (!handoutImagePopup) {
            fetch('https://portal.stroetmann.de/bilder/output/Messeportal/digitalerhandzettel/popup/' + toYear + '_' + fromWeekFormatted + '_link.png').then(
                res => res.text()).then(res => setName(res));
        }
    }, [])

    useEffect(() => {
        if (name == 'Datei existiert nicht.') {
            setName("");
        } else if (name == '[HO_BLAETTERKATALOG]') {
            setName(documentUrl);
        } else {
            setTarget(name.startsWith("http") ? "_blank" : "");                    
        }
    }, [name])

    const close = (e) => {
        e.target.parentElement.parentElement.style.display = 'none';
        handoutImagePopupContext.setHandoutImagePopup(toYear + '_' + fromWeekFormatted);
    }

    if (!handoutImagePopup) {
        return (
            <ModalLayer id='popup'>
                <ModalImage>
                    <CloseButtonPopup onClick={close} />
                    {name != "" && (
                        <a href={name} target={target}>
                            <ImageProductImage
                                src={'https://portal.stroetmann.de/bilder/output/Messeportal/digitalerhandzettel/popup/' + toYear + '_' + fromWeekFormatted + '.png'}
                                onLoad={(e) => e.currentTarget.parentElement.parentElement.parentElement.style.display = 'flex'}
                            />
                        </a>
                    )}
                    {name == "" && (
                        <ImageProductImage
                            src={'https://portal.stroetmann.de/bilder/output/Messeportal/digitalerhandzettel/popup/' + toYear + '_' + fromWeekFormatted + '.png'}
                            onLoad={(e) => e.currentTarget.parentElement.parentElement.style.display = 'flex'}
                        />
                    )}
                </ModalImage>
            </ModalLayer>
        );
    } else {
        return (null);
    }
};

export default HandoutImagePopup;