import styled from "styled-components";
import CSSTransition from "react-transition-group/CSSTransition";

const SlideInFromLeft = styled(CSSTransition).attrs({ classNames: "slideInFromLeft", timeout: 150 })`
&.slideInFromLeft-enter {
    transform: translateX(-100%);
}
&.slideInFromLeft-enter-active {
    transform: translateX(0%);
}
&.slideInFromLeft-exit {
    transform: translateX(0%);
}
&.slideInFromLeft-exit-active {
    transform: translateX(-100%);
}
&.slideInFromLeft-enter-active,
&.slideInFromLeft-exit-active {
    transition: transform 150ms;
}
`;

export default SlideInFromLeft;