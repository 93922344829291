import React, { HTMLProps, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { startOfWeek, endOfWeek, getDay, addDays, getYear, getWeek, isValid } from "date-fns";
import { useParams } from "react-router";
import Chalkboard from "../style/Chalkboard";
import flexCss from "../utils/flexCss";
import positionCss from "../utils/positionCss";
import valuesCss from "../utils/valuesCss";
import dontForward from "../utils/dontForward";
import ArrowSmallIcon from "../common/icons/ArrowSmallIcon";
import BurgerMenuIcon from "../common/icons/BurgerMenuIcon";
import SelectArrow from "../common/icons/SelectArrow";
import SlideRight from "../common/transitions/SlideRight";
import useBreakpointMatch from "../hooks/useBreakpointMatch";
import MarketInfoContext from "../contexts/MarketInfoContext";
import { useOverlayState } from "../contexts/OverlayContext";
import { MarketInfoDetailsFragment } from "../generated/graphql";

const PdfCatalogIcon = styled("img").attrs({ src: "/assets/images/pdf-catalog.svg" })({
    width: 40
});

const LinkArrow = styled(ArrowSmallIcon)({
    display: "inline-block",
    height: "1em",
    fontSize: "1em",
    lineHeight: "1em",
    verticalAlign: "middle",
    marginLeft: "0.5em"
});

const ArrowLink = styled(({ children, color, ...props }: HTMLProps<HTMLAnchorElement> & { color?: string }) => (
    <a {...props}>
        {children}<LinkArrow color={color || "currentColor"} />
    </a>
)).withConfig(dontForward("color"))({
    textDecoration: "none"
});

const BurgerMenuOpener = styled(props => (
    <div {...props}>
        <BurgerMenuIcon color="currentColor" style={{ height: "100%" }} />
    </div>
)).attrs({ color: "currentColor" })(({ theme }) => ({
    ...flexCss("v", "center", "center"),
    cursor: "pointer",
    height: theme.baseUnit * 2,
    width: theme.baseUnit * 2,
    position: "relative",
    "&:after": {
        content: "''",
        display: "block",
        ...positionCss("abs"),
        margin: -(theme.baseUnit * 2),
        borderRadius: (theme.baseUnit * 6) / 2,
        background: "rgba(255, 255, 255, 0.2)",
        opacity: 0,
        transform: "scale(0.5)",
        transition: "transform ease-in-out 50ms, opacity linear 50ms"
    },
    "&:hover:after": {
        opacity: 1,
        transform: "scale(1)"
    }
}));

const dateFormatterWithYear = new Intl.DateTimeFormat("de-DE", { year: "numeric", month: "numeric", day: "numeric" });
const dateFormatterWithoutYear = new Intl.DateTimeFormat("de-DE", { year: undefined, month: "numeric", day: "numeric" });

const OfferPeriodText: React.FC<{ from: Date, to: Date, dense?: boolean }> = ({ from, to, dense }) => {

    const fromYear = getYear(from);
    const toYear = getYear(to);
    const fromFormatted = (fromYear === toYear) ? dateFormatterWithoutYear.format(from) : dateFormatterWithYear.format(from);
    const toFormatted = dateFormatterWithYear.format(to);
    const fromWeekFormatted = getWeek(from, { weekStartsOn: 1, firstWeekContainsDate: 4 });

    if (dense) {
        return (
            <p>
                <em>{fromFormatted}</em> bis<br />
                <em>{toFormatted}</em>
            </p>
        );
    }

    return <p>Angebote gültig vom <em>{fromFormatted} bis {toFormatted}</em><br />KW {fromWeekFormatted}/{toYear}</p>;
};

const HeaderLeftPart = styled("div")(({ theme }) => ({
    ...flexCss("v", "space-between"),
    alignSelf: "stretch",
    flexShrink: 0,
    display: "grid",
    gridTemplateColumns: `${theme.baseUnit * 6}px auto`,
    gridAutoRows: "1fr",
    justifyItems: "center",
    alignItems: "center",
    gap: valuesCss("px", 0, theme.baseUnit * 2),  
    padding: valuesCss("px", theme.baseUnit * 1, 0),   
    fontSize: 16,
    "& > :nth-child(2n)": {
        justifySelf: "start"
    }
}));

const HeaderRightPart = styled("div")(({ theme }) => ({  
    padding: valuesCss("px", theme.baseUnit * 2, 0),  
    position: "relative",
    cursor: "pointer"
}));

const MarketSelectControl = styled("div")(({ theme }) => ({
    position: "absolute",
    left: 58,
    bottom: 18,
    fontSize: 13,
    lineHeight: "1",
    [theme.media("sm")]: {
        left: 67,
        bottom: 10
    }
}));

const MarketSelectArrow = styled("button")(({ theme }) => ({
    ...positionCss("abs", "right"),
    ...flexCss("v", "center", "center"),
    zIndex: 1,
    background: `linear-gradient(0deg, ${theme.palette.drawer.main}, rgb(90, 90, 90), ${theme.palette.drawer.main})`,
    color: theme.palette.drawer.contrastText,
    borderLeft: "2px solid rgb(80, 80, 80)",
    width: 22,
    "&:hover": {
        background: `linear-gradient(0deg, ${theme.palette.drawer.main}, rgb(110, 110, 110), ${theme.palette.drawer.main})`,
    },
    "& > span": {
        fontSize: 22,
        marginTop: 12
    }
}));

const MarketNamAndSelect: React.FC<{ showSelectHint: boolean, market: MarketInfoDetailsFragment | null, markets: MarketInfoDetailsFragment | null  }> = ({ showSelectHint, market, markets }) => {

    if (!market || markets?.length <= 1) {        
        return (
            <></>
        );
    }

    return (
        <MarketSelectControl>
            {showSelectHint ? "Markt auswählen" : market?.nameShort}
        </MarketSelectControl>
    );
};

const HeaderLogo = styled("img").attrs({
    src: "/assets/images/edeka-logo-header.png"
})(({ theme }) => ({
    height: 46,
    marginLeft: 16,
    marginRight: 8,
    objectFit: "contain",
    [theme.media("sm")]: {
        height: 56
    }
}));

const HeaderChalkboard = styled(Chalkboard)(({ theme }) => ({
    ...flexCss("h", "space-between"),
    ...positionCss("fix", "top"),
    zIndex: theme.zIndex.bars,
    height: theme.layout.headerHeight,
    padding: valuesCss("px", 0, theme.baseUnit * 2),
    fontWeight: theme.typography.bold
}));

interface IHeaderProps {
    onBurgerMenuClick: () => void;
    documentUrl: string | null;
    marketNamAndSelect: string | null;
}

const Header: React.FC<IHeaderProps> = ({ onBurgerMenuClick, documentUrl, marketNamAndSelect }) => {

    const longer = useBreakpointMatch("sm");

    const { preview_date } = useParams();
    
    let now = new Date();

    if (preview_date) {
        const previewDate = new Date(preview_date);
        if (isValid(previewDate)) {
            now = previewDate;
        }
    }

    if (getDay(now) === 0) {
        now = addDays(now, 1); // show next week on sundays
    }

    const from = startOfWeek(now, { weekStartsOn: 1 });
    const to = endOfWeek(now, { weekStartsOn: 1 });

    const { market, hasSetMarket, markets } = useContext(MarketInfoContext);
    const [previewMarketSelect, setPreviewMarketSelect] = useState(false);
    const [openMarketsMenu, setOpenMarketsMenu] = useOverlayState("markets-menu");

    const slideRightRef = useRef(null);

    useEffect(() => {

        let hideTimeout: number | undefined = undefined;
        let interval: number | undefined = undefined;

        if (!hasSetMarket) {
            interval = window.setInterval(() => {
                setPreviewMarketSelect(true);
                hideTimeout = window.setTimeout(() => {
                    if (!openMarketsMenu) {
                        setPreviewMarketSelect(false);
                    }
                }, 5000);
            }, 12000);
        } else {
            setPreviewMarketSelect(false);
        }

        return () => {
            window.clearInterval(interval);
            window.clearTimeout(hideTimeout);
        };
    }, [openMarketsMenu, hasSetMarket]);

    const onRightHeaderClick = () => {
        setOpenMarketsMenu(!openMarketsMenu);
        setPreviewMarketSelect(!openMarketsMenu);
    };

    return (
        <HeaderChalkboard as="header">
            <HeaderLeftPart>
                {longer ? (
                    <>
                        {documentUrl && (
                            <>
                                <PdfCatalogIcon />
                                <ArrowLink href={documentUrl}>Als PDF-Dokument ansehen</ArrowLink>
                            </>
                        )}
                        <BurgerMenuOpener onClick={onBurgerMenuClick} />
                        <OfferPeriodText from={from} to={to} />
                    </>
                ) : (
                    <>
                        {documentUrl && (
                            <>
                                <PdfCatalogIcon />
                                <ArrowLink href={documentUrl}>PDF</ArrowLink>
                            </>
                        )}
                        <BurgerMenuOpener onClick={onBurgerMenuClick} />
                        <OfferPeriodText from={from} to={to} dense={true} />
                    </>
                )}
            </HeaderLeftPart>
            <SlideRight
                mountOnEnter={true}
                unmountOnExit={true}
                nodeRef={slideRightRef}
                in={previewMarketSelect || openMarketsMenu}
            >
                <MarketSelectArrow ref={slideRightRef} onClick={onRightHeaderClick}>
                    <SelectArrow color={"currentColor"} style={{ height: "75%" }} />
                </MarketSelectArrow>
            </SlideRight>
            <HeaderRightPart onClick={onRightHeaderClick}>
                <HeaderLogo />
                {marketNamAndSelect && (
                    <>
                        <MarketNamAndSelect
                            market={market}
                            showSelectHint={previewMarketSelect || openMarketsMenu}
                            markets={markets}
                        />
                    </>
                )}        
            </HeaderRightPart>
        </HeaderChalkboard>
    );
};

export default Header;