import { addDays } from "date-fns";
import React, { createContext, useCallback, useEffect } from "react";
import { useLocalstorageState } from "rooks";

interface IHandoutImagePopup {
    endDate: string;
    handoutImagePopupId: string;
}

export interface IHandoutImagePopupContext {
    setHandoutImagePopup: (handoutImagePopupId: string) => void;
    getHandoutImagePopup: (handoutImagePopupId: string) => object;
}

const noopHandlers = {
    // eslint-disable-next-line
    setHandoutImagePopup: (handoutImagePopupId: string) => {},
    // eslint-disable-next-line
    getHandoutImagePopup: (handoutImagePopupId: string) => ""
};

const HandoutImagePopupContext = createContext<IHandoutImagePopupContext>(noopHandlers);

export default HandoutImagePopupContext;

const localStorageKey = "handoutimagepopup";

export const HandoutImagePopupProvider: React.FC = ({ children }) => {

    const [handoutImagePopup, setHandoutImagePopupls] = useLocalstorageState<Array<IHandoutImagePopup>>(localStorageKey, []);

    useEffect(() => {

        const now = new Date();

        try {
            setHandoutImagePopupls(handoutImagePopup.filter(c => new Date(c.endDate) > now));

        } catch (error) {

            console.error(`corrupted entries found in local-storage. Cleaning up "${localStorageKey}" key`, error);
            setHandoutImagePopupls([]);
        }

    }, []);

    const setHandoutImagePopup = useCallback((handoutImagePopupId: string) => {

        const existingHandoutImagePopup = handoutImagePopup.find(c => c.handoutImagePopupId === handoutImagePopupId);
        const newHandoutImagePopup = existingHandoutImagePopup || { handoutImagePopupId, endDate: addDays(new Date(), 1).toJSON() };

        if (!existingHandoutImagePopup) {
            handoutImagePopup.push(newHandoutImagePopup);
        }
        setHandoutImagePopupls([ ...handoutImagePopup ]);

    }, [handoutImagePopup, setHandoutImagePopupls]);

    const getHandoutImagePopup = (handoutImagePopupId: string) => handoutImagePopup
        .find(h => h.handoutImagePopupId === handoutImagePopupId);

    return (
        <HandoutImagePopupContext.Provider value={{ setHandoutImagePopup, getHandoutImagePopup }}>
            {children}
        </HandoutImagePopupContext.Provider>
    );
};