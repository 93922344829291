import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import styled, { CSSObject } from "styled-components";
import { startOfWeek, endOfWeek, getDay, addDays, getYear, getWeek, isValid } from "date-fns";
import PossibleImage from "../../common/PossibleImage";


const HandoutHeaderImage = styled(PossibleImage)({
    width: "100%",
    objectFit: "contain",
    objectPosition: "center",
    maxHeight: 256,
    display: "none"
});

interface IHandoutHeaderProps {
    documentUrl: string | null;
}

const HandoutHeader: React.FC<IHandoutHeaderProps> = ({ documentUrl }) => {

    const { preview_date } = useParams();

    const [name, setName] = useState("");
    const [target, setTarget] = useState("");

    let now = new Date();

    if (preview_date) {
        const previewDate = new Date(preview_date);
        if (isValid(previewDate)) {
            now = previewDate;
        }
    }

    if (getDay(now) === 0) {
        now = addDays(now, 1); // show next week on sundays
    }

    const from = startOfWeek(now, { weekStartsOn: 1 });
    const to = endOfWeek(now, { weekStartsOn: 1 });
    const fromYear = getYear(from);
    const toYear = getYear(to);
    const fromWeekFormatted = getWeek(from, { weekStartsOn: 1, firstWeekContainsDate: 4 }).toString().padStart(2, 0);

    useEffect(() => {
        fetch('https://portal.stroetmann.de/bilder/output/Messeportal/digitalerhandzettel/header/' + toYear + '_' + fromWeekFormatted + '_link.png').then(
            res => res.text()).then(res => setName(res));
    }, [])

    useEffect(() => {
        if (name == 'Datei existiert nicht.') {
            setName("");
        } else if (name == '[HO_BLAETTERKATALOG]') {
            setName(documentUrl);
        } else {
            setTarget(name.startsWith("http") ? "_blank" : "");                    
        }
    }, [name])

    return (
        <>
            {name != "" && (
                <a href={name} target={target}>
                    <HandoutHeaderImage
                        src={'https://portal.stroetmann.de/bilder/output/Messeportal/digitalerhandzettel/header/' + toYear + '_' + fromWeekFormatted + '.png'}
                        onLoad={(e) => e.currentTarget.style.display = 'inline'}
                    />
                </a>
            )}
            {name == "" && (
                <HandoutHeaderImage
                    src={'https://portal.stroetmann.de/bilder/output/Messeportal/digitalerhandzettel/header/' + toYear + '_' + fromWeekFormatted + '.png'}
                    onLoad={(e) => e.currentTarget.style.display = 'inline'}
                />
            )}
        </>
    );
};

export default React.memo(HandoutHeader);