import React, { HTMLProps, useContext } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";
import ArrowSmallIcon from "../../../common/icons/ArrowSmallIcon";
import CheckmarkIcon from "../../../common/icons/CheckmarkIcon";
import InfoIcon from "../../../common/icons/InfoIcon";
import PrimaryIconButton from "../../../common/PrimaryIconButton";
import FiltersContext from "../../../contexts/FiltersContext";
import flexCss from "../../../utils/flexCss";
import useBreakpointMatch from "../../../hooks/useBreakpointMatch";
import valuesCss from "../../../utils/valuesCss";
import makeTextBreakable from "../../../utils/makeTextBreakable";
import { useCategoriesQuery } from "../../../generated/graphql";

const ArrowLeft = styled(ArrowSmallIcon)({
    height: 24,
    verticalAlign: "middle",
    marginRight: "1em",
    transform: "rotate(180deg)"
});

const AmbientButton = styled("button")(({ theme }) => ({
    fontSize: 14,
    lineHeight: "1.25em",
    textAlign: "left",
    padding: valuesCss("px", theme.baseUnit, theme.baseUnit * 2),
    "&:hover": {
        backgroundColor: theme.palette.paper.main
    },
    "& > span": {
        display: "inline-block",
        verticalAlign: "middle"
    }
}));

const AllArticlesSeenText = styled(({ small, ...props }: HTMLProps<HTMLParagraphElement> & { small: boolean }) => (
    <p {...props}>
        {small ? (
            <>
                Alle Artikel <br />
                angesehen
            </>
        ) : (
            <>
                Alle Artikel angesehen<br />
                Weiter zur nächsten Kategorie
            </>
        )}
    </p>
))(({ small }) => ({
    fontSize: 14,
    textAlign: small ? "left" : "center"
}));

const AllArticlesSeenIcon = styled((props: React.HTMLProps<HTMLDivElement>) => (
    <div {...props}>
        <CheckmarkIcon />
    </div>
))(({ theme }) => ({
    border: `2px solid ${theme.palette.tertiary.main}`,
    width: 30,
    height: 30,
    borderRadius: "50%",
    position: "relative",
    color: "green",
    marginRight: 10,
    "& > svg": {
        position: "absolute",
        top: "50%",
        left: "50%",
        height: "30%",
        transform: "translate(-50%, -50%)"
    }
}));

const AllArticlesSeenElement = styled("div")({
    ...flexCss("h"),
    "&:only-child": {
        gridColumn: "span 2",
        justifySelf: "center"
    }
});

const CategoryFooterGrid = styled("div")(({ theme }) => ({
    marginTop: theme.baseUnit * 2,
    display: "grid",
    gap: theme.baseUnit * 2,
    alignItems: "center",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr 1fr",
    "& > div:last-child:not(:only-child)": {
        justifySelf: "end",
        maxWidth: 280
    },
    [theme.media("sm")]: {
        gridTemplateColumns: "1fr auto 1fr"
    }
}));

const UnseenFilteredArticlesInfo = styled("p")(({ theme }) => ({
    color: theme.palette.primary.main,
    marginTop: theme.baseUnit * 2,
    "a": {
        fontWeight: theme.typography.bold,
        textDecoration: "underline",
        cursor: "pointer"
    },
    "svg": {
        height: "1em",
        verticalAlign: "middle",
        marginRight: "0.25em"
    }
}));

interface ICategoryFooterProps {
    categoryId: string;
    categories: Array<{
        id: string;
        name: string;
        articlesCount: number;
    }>;
    allArticlesCount: number;
    filteredArticlesCount: number;
    canScrollToSeeMore: boolean;
}

const CategoryFooter: React.FC<ICategoryFooterProps> = ({ categoryId, categories, allArticlesCount, filteredArticlesCount, canScrollToSeeMore }) => {

    const isAtLeastSmall = useBreakpointMatch("sm");

    const filtersContext = useContext(FiltersContext);

    const navigate = useNavigate();

    const currentCategoryIndex = categories.findIndex(c => c.id === categoryId);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const prevCategory = Number.isInteger(currentCategoryIndex) ? categories[currentCategoryIndex! - 1] : undefined;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const nextCategory = Number.isInteger(currentCategoryIndex) ? categories[currentCategoryIndex! + 1] : undefined;

    const goToPrevCategory = () => navigate(prevCategory ? "../" + prevCategory.id : categoryId);
    const goToNextCategory = () => navigate(nextCategory ? "../" + nextCategory.id : categoryId);

    const unseenFilteredCount = allArticlesCount - filteredArticlesCount;

    if (unseenFilteredCount) {

        return (
            <UnseenFilteredArticlesInfo>
                <InfoIcon />
                {unseenFilteredCount} weitere Artikel anzeigen <a onClick={() => filtersContext.disableAll()}>alle Filter aufheben</a>
            </UnseenFilteredArticlesInfo>
        );
    }

    const backButton = (
        <AmbientButton onClick={goToPrevCategory}>
            <ArrowLeft />
            <span>
                zurück zu<br />
                {makeTextBreakable(prevCategory?.name || "")}
            </span>
        </AmbientButton>
    );

    const allSeenElement = (
        <AllArticlesSeenElement>
            <AllArticlesSeenIcon />
            <AllArticlesSeenText small={!isAtLeastSmall} />
        </AllArticlesSeenElement>
    );

    const forwardButton = (
        <PrimaryIconButton size="large" onClick={goToNextCategory} IconRight={ArrowSmallIcon}>
            weiter zu {makeTextBreakable(nextCategory?.name || "")}
        </PrimaryIconButton>
    );

    if (isAtLeastSmall) {

        return (
            <CategoryFooterGrid>
                <div>
                    {!!prevCategory && backButton}
                </div>
                {allSeenElement}
                <div>
                    {!!nextCategory && !canScrollToSeeMore && forwardButton}
                </div>
            </CategoryFooterGrid>
        );
    }

    return (
        <CategoryFooterGrid>
            {!!prevCategory && !nextCategory && backButton}
            {allSeenElement}
            {!!nextCategory && forwardButton}
        </CategoryFooterGrid>
    );
};

export default CategoryFooter;