import { compress, decompress } from "lzutf8";

type ItemData = [id: string, amount: number, done: boolean];

export default class ShoppingListString {

    readonly items: Array<ItemData>;

    constructor(items: Array<ItemData>) {
        this.items = items;
    }

    static parse(raw: string) {

        if (!raw) {
            return null;
        }

        let source = "";

        try {
            source = decompress(raw, { inputEncoding: "Base64" });

        } catch (error) {
            console.error("Failed to decode shopping list");
            return null;
        }

        const itemsStrings = source.split(",") || [];

        if (itemsStrings.length) {

            const items = itemsStrings.map<ItemData | null>(str => {

                const match = str.match(/^(.+)_x(\d+)_(t|d)$/);
                
                if (!match) {
                    return null;
                }

                const [, id, amount, flag] = match;

                return [id, +amount, flag === "d"];

            }).filter((item): item is ItemData => !!item);

            return new ShoppingListString(items);
        }

        return null;
    }

    toString() {

        const source = this.items.map(([id, amount, done]) => `${id}_x${amount}_${done ? "d" : "t"}`).join(",");

        return compress(source, { outputEncoding: "Base64" });
    }
}