import React, { useRef } from "react";
import BackLayer from "../common/BackLayer";
import SideMenuContainer from "../common/SideMenuContainer";
import SlideInFromLeft from "../common/transitions/SlideInFromLeft";
import SideMenuCategories from "./SideMenuCategories";

interface ISideMenuProps {
    open: boolean;
    onClose: () => void;
    handoutId?: string;
    categories: Array<{ id: string, name: string, articlesCount: number }>;
}

const SideMenu: React.FC<ISideMenuProps> = ({ open, onClose, handoutId, categories }) => {

    const nodeRef = useRef(null);

    const openMenu = open && !!handoutId;

    return (
        <>
            {openMenu && (
                <BackLayer onClick={onClose} />
            )}
            <SlideInFromLeft
                nodeRef={nodeRef}
                in={openMenu}
                mountOnEnter={true}
                unmountOnExit={true}
            >
                <SideMenuContainer ref={nodeRef} open={openMenu} stickTo="left">
                    <SideMenuCategories
                        categories={categories}
                        onItemClick={onClose}
                        handoutId={handoutId || ""}
                    />
                </SideMenuContainer>
            </SlideInFromLeft>
        </>
    );
};

export default SideMenu;