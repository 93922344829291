import React, { HTMLProps } from "react";
import styled from "styled-components";
import clickAreaCss from "../../../utils/clickAreaCss";
import CloseIcon from "../../../common/icons/CloseIcon";

const CloseButton = styled((props: HTMLProps<HTMLAnchorElement>) => (
    <a {...props}>
        <CloseIcon />
    </a>
))(({ theme }) => ({
    display: "block",
    position: "absolute",
    top: 18,
    right: 14,
    width: 24,
    cursor: "pointer",
    "&:hover": {
        color: theme.palette.black
    },
    "& > svg": {
        height: "100%"
    },
    ...clickAreaCss()
}));

export default CloseButton;