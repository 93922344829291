import styled from "styled-components";

const BackLayer = styled("div")(({ theme }) => ({
    position: "fixed",
    top: theme.layout.headerHeight,
    bottom: theme.layout.footerHeight,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    zIndex: theme.zIndex.overlay - 1
}));

export default BackLayer;