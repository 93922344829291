import styled from "styled-components";
import ArrowDownSmallIcon from "../../common/icons/ArrowDownSmallIcon";
import PrimaryIconButton from "../../common/PrimaryIconButton";

const MoreArticlesButton = styled(PrimaryIconButton).attrs({ size: "large", IconRight: ArrowDownSmallIcon })(({ theme }) => ({
    position: "fixed",
    right: theme.baseUnit * 2,
    bottom: 104,
    boxShadow: "2px 6px 16px rgba(0, 0, 0, 0.3)",
    zIndex: theme.zIndex.flying
}));

export default MoreArticlesButton;