import React from "react";
import { render } from "react-dom";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache/inmemory/inMemoryCache";
import { ApolloClient } from "@apollo/client/core/ApolloClient";
import { offsetLimitPagination } from "@apollo/client/utilities/policies/pagination";
import loadable from "@loadable/component";
import { ThemeProvider } from "styled-components";
import { ShoppingListProvider } from "./contexts/ShoppingListContext";
import { CategorySeenProvider } from "./contexts/CategorySeenContext";
import { FiltersProvider } from "./contexts/FiltersContext";
import { CurrentHandoutProvider } from "./contexts/CurrentHandoutContext";
import { HandoutImagePopupProvider } from "./contexts/HandoutImagePopupContext";
import { OverlayContextProvider } from "./contexts/OverlayContext";
import { TrackingContextProvider } from "./contexts/TrackingContext";
import { MarketInfoProvider } from "./contexts/MarketInfoContext";
import Root from "./routes";
import Handout from "./routes/handout";
import Back from "./routes/handout/back";
import AllArticles from "./routes/handout/allArticles";
import Category from "./routes/handout/category";
import Catchall from "./routes/catchall";
import theme from "./style/theme";
import NormalizeCss from "./style/NormalizeCss";
import BaseStyle from "./style/BaseStyle";
import { TypedTypePolicies } from "./generated/apollo-helpers";

const Variants = loadable(() => import("./routes/handout/variants"));
const ShoppingList = loadable(() => import("./routes/shopping-list"));
const ShoppingListItemDetails = loadable(() => import("./routes/shopping-list/details"));
const Subscribe = loadable(() => import("./routes/subscribe"));

const filteredOffsetLimit = offsetLimitPagination(["filters"]);

const typePolicies: TypedTypePolicies = {
    Handout: {
        fields: {
            articles: filteredOffsetLimit,
            uniqueArticles: filteredOffsetLimit,
            article: {
                keyArgs: ["id"]
            }
        }
    },
    HandoutCategory: {
        fields: {
            articles: filteredOffsetLimit,
            uniqueArticles: filteredOffsetLimit
        }
    }
};

const client = new ApolloClient({
    uri: "/graphql",
    cache: new InMemoryCache({ typePolicies })
});

render((
    <React.StrictMode>
        <BrowserRouter>
            <TrackingContextProvider>
                <ApolloProvider client={client}>
                    <ShoppingListProvider>
                        <CategorySeenProvider>
                            <FiltersProvider>
                                <MarketInfoProvider>
                                    <OverlayContextProvider>
                                        <ThemeProvider theme={theme}>
                                            <CurrentHandoutProvider>
                                                <HandoutImagePopupProvider>
                                                    <NormalizeCss />
                                                    <BaseStyle />
                                                    <Routes>
                                                        <Route path="/" element={<Root />}>
                                                            <Route index element={<Navigate to="handout" replace />} />
                                                            <Route path="handout/*" element={<Handout />}>
                                                                <Route index element={<Navigate to="articles" replace />} />
                                                                <Route path="back" element={<Back />} />
                                                                <Route path="articles" element={<AllArticles />}>
                                                                    <Route path="variants/:article" element={<Variants />} />
                                                                </Route>
                                                                <Route path=":category" element={<Category />}>
                                                                    <Route path="variants/:article" element={<Variants />} />
                                                                </Route>
                                                            </Route>
                                                            <Route path="shopping-list" element={<ShoppingList />}>
                                                                <Route path=":listItem" element={<ShoppingListItemDetails />} />
                                                            </Route>
                                                            <Route path="subscribe" element={<Subscribe />} />
                                                        </Route>
                                                        <Route path="/preview/:preview_date" element={<Root />}>
                                                            <Route index element={<Navigate to="handout" replace />} />
                                                            <Route path="handout/*" element={<Handout />}>
                                                                <Route index element={<Navigate to="articles" replace />} />
                                                                <Route path="back" element={<Back />} />
                                                                <Route path="articles" element={<AllArticles />}>
                                                                    <Route path="variants/:article" element={<Variants />} />
                                                                </Route>
                                                                <Route path=":category" element={<Category />}>
                                                                    <Route path="variants/:article" element={<Variants />} />
                                                                </Route>
                                                            </Route>
                                                        </Route>
                                                        <Route path="*" element={<Catchall />} />
                                                    </Routes>
                                                </HandoutImagePopupProvider>
                                            </CurrentHandoutProvider>
                                        </ThemeProvider>
                                    </OverlayContextProvider>
                                </MarketInfoProvider>
                            </FiltersProvider>
                        </CategorySeenProvider>
                    </ShoppingListProvider>
                </ApolloProvider>
            </TrackingContextProvider>
        </BrowserRouter>
    </React.StrictMode>
), document.getElementById("root"));