import styled from "styled-components";
import dontForward from "../utils/dontForward";
import valuesCss from "../utils/valuesCss";

interface IPrimaryButtonProps {
    size?: "large";
}

const PrimaryButton = styled("button").withConfig(dontForward("size"))<IPrimaryButtonProps>(({ theme, size, disabled }) => ({
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.families.headline,
    fontWeight: theme.typography.bold,
    lineHeight: "1.2",
    cursor: disabled ? "default" : "pointer",
    ...(size === "large" ? ({
        padding: valuesCss("px", theme.baseUnit * 1.5, theme.baseUnit * 3),
        fontSize: 22,
        textTransform: "uppercase"
    }) : ({
        fontSize: 16,
        padding: valuesCss("px", theme.baseUnit / 2, theme.baseUnit)
    }))
}));

export default PrimaryButton;