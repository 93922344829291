import React from "react";
import styled from "styled-components";
import flexCss from "../utils/flexCss";
import positionCss from "../utils/positionCss";
import TileLoader from "./icons/TileLoader";

const LoadingOverlay = styled(({ active, ...props }: React.HTMLProps<HTMLDivElement> & { active: boolean }) => (
    <div {...props} style={{ display: active ? "flex" : "none" }}>
        <TileLoader style={{ width: 200, height: 200 }} />
    </div>
))({
    ...positionCss("fix"),
    ...flexCss("v", "center", "center"),
    zIndex: 1
});

export default LoadingOverlay;