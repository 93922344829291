import { addDays, getDay, startOfWeek } from "date-fns";
import React, { useContext, useMemo, useRef } from "react";
import styled from "styled-components";
import BackLayer from "../common/BackLayer";
import SideMenuContainer from "../common/SideMenuContainer";
import SlideInFromRight from "../common/transitions/SlideInFromRight";
import MarketInfoContext from "../contexts/MarketInfoContext";
import { BusinessHoursDetailsFragment } from "../generated/graphql";
import dontForward from "../utils/dontForward";
import flexCss from "../utils/flexCss";
import rgbToRgba from "../utils/rgbToRgba";

const MarketsMenuContainer = styled(SideMenuContainer)(({ theme }) => ({
    padding: theme.baseUnit,
    background: theme.palette.background.main,
    color: theme.palette.background.contrastText
}));

const MarketTile = styled("div")(({ theme }) => ({
    display: "block",
    cursor: "pointer",
    background: theme.palette.white,
    padding: theme.baseUnit * 2,
    position:"relative",
    "&:not(:last-child)": {
        marginBottom: theme.baseUnit
    },
    "&:hover": {
        background: rgbToRgba(theme.palette.white, 0.5)
    }
}));

const MarketTitle = styled("h3")({
    marginRight: 80 //  space for knobs
});

const BusinessHoursRow = styled("tr").withConfig(dontForward("today"))<{ today: boolean }>(({ theme, today }) => ({
    fontWeight: today ? theme.typography.bold : theme.typography.regular,
    "td:not(:last-child)": {
        paddingRight: "0.8em"
    }
}));

const SelectMarketTextButton = styled("span")(({ theme }) => ({
    color: theme.palette.primary.main,
    fontFamily: theme.typography.families.headline,
    fontWeight: theme.typography.bold,
    fontSize: 20
}));

const KnobsContainer = styled("div")(({ theme }) => ({
    position: "absolute",
    top: theme.baseUnit * 2,
    right: theme.baseUnit * 2,
    ...flexCss("h")
}));

const LinkKnob = styled("a")(({ theme }) => ({
    height: theme.baseUnit * 6,
    width: theme.baseUnit * 6,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    marginLeft: theme.baseUnit,
    display: "block",
    [theme.media("sm")]: {
        height: theme.baseUnit * 4,
        width: theme.baseUnit * 4
    }
}));

interface IBusinessHoursTableProps {
    businessHours: Array<BusinessHoursDetailsFragment>;
    currentDay: number;
    weekdays: Array<string>;
}

const BusinessHoursTable: React.FC<IBusinessHoursTableProps> = ({ businessHours, currentDay, weekdays }) => {

    let businessHoursSorted = [...businessHours];
    const firstItem = businessHoursSorted.shift();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    businessHoursSorted.push(firstItem!);

    businessHoursSorted = businessHoursSorted.filter(({ day, open }) => open || day !== 0);

    return (
        <table>
            <tbody>
                {businessHoursSorted.map(hours => (
                    <BusinessHoursRow
                        key={hours.id}
                        today={hours.day === currentDay}
                    >
                        <td>{weekdays[hours.day]}</td>
                        {hours.open ? (
                            <>
                                <td>{hours.openFrom}</td>
                                <td>- {hours.openTo}</td>
                            </>
                        ) : (
                            <td colSpan={2}>geschlossen</td>
                        )}
                    </BusinessHoursRow>
                ))}
            </tbody>
        </table>
    );
};

const MarketsMenu: React.FC<{ open: boolean, onClose: () => void }> = ({ open, onClose }) => {

    const marketInfoContext = useContext(MarketInfoContext);
    const nodeRef = useRef(null);

    const today = new Date();

    const weekdays = useMemo(() => {
        const weekdayFormat = new Intl.DateTimeFormat("de-DE", { weekday: "long" });
        const monday = startOfWeek(today);
        return new Array(7).fill(null).map((e, i) => weekdayFormat.format(addDays(monday, i)));
    }, []);

    const currentDay = getDay(today);

    const onMarketSelect = (id: string) => {
        marketInfoContext.setMarketId(id);
        onClose();
    };

    return (
        <>
            {open && (
                <BackLayer onClick={onClose} />
            )}
            <SlideInFromRight
                nodeRef={nodeRef}
                in={open}
                mountOnEnter={true}
                unmountOnExit={true}
            >
                <MarketsMenuContainer ref={nodeRef} open={open} stickTo="right">
                    {marketInfoContext.markets.map(m => (
                        <MarketTile key={m.id} onClick={() => onMarketSelect(m.id)}>
                            <MarketTitle>{m.name}</MarketTitle>
                            <p>{m.address.map(a => (
                                <React.Fragment key={a}>{a}<br /></React.Fragment>)
                            )}</p>
                            <BusinessHoursTable
                                businessHours={m.businessHours}
                                currentDay={currentDay}
                                weekdays={weekdays}
                            />
                            <SelectMarketTextButton>{marketInfoContext.markets.length > 1 ? "auswählen" : "schließen"}</SelectMarketTextButton>
                            <KnobsContainer>
                                {m.googleMapsLink && (
                                    <LinkKnob
                                        style={{ backgroundImage: "url(/assets/icons/knob_maps.svg)" }}
                                        href={m.googleMapsLink}
                                        target="_blank"
                                    />
                                )}
                                {m.facebookLink && (
                                    <LinkKnob
                                        style={{ backgroundImage: "url(/assets/icons/knob_fb.svg)" }}
                                        href={m.facebookLink}
                                        target="_blank"
                                    />
                                )}
                            </KnobsContainer>
                        </MarketTile>
                    ))}
                </MarketsMenuContainer>
            </SlideInFromRight>
        </>
    );
};

export default MarketsMenu;