import { createGlobalStyle } from "styled-components";

const BaseStyle = createGlobalStyle(({ theme }) => `
    @font-face {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        src: url('/assets/fonts/work-sans-regular.woff2') format('woff2');
        font-display: swap;
    }
    @font-face {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        src: url('/assets/fonts/work-sans-bold.woff2') format('woff2');
        font-display: swap;
    }
    @font-face {
        font-family: 'Plak';
        font-style: normal;
        font-weight: 700;
        src: url('/assets/fonts/plak-lt-black-condensed.woff2') format('woff2');
        font-display: swap;
    }
    @font-face {
        font-family: 'Anton';
        font-style: normal;
        font-weight: 400;
        src: url('/assets/fonts/anton-regular.woff2') format('woff2');
        unicode-range: U+0020-003B;
        font-display: swap;
    }
    @font-face {
        font-family: 'Amsi Pro Cond';
        font-style: normal;
        font-weight: 700;
        src: url('/assets/fonts/amsiprocond-black.woff2') format('woff2');
        unicode-range: U+0030-003A, U+002C, U+002E;
        font-display: swap;
    }
    html {
        font-size: 16px;
        line-height: 1.25;
        font-family: 'Work Sans', sans-serif;
        box-sizing: border-box;
        overscroll-behavior-y: none;
    }
    *, ::before, ::after {
        box-sizing: inherit;
    }
    html, body, #root {
        width: 100%;
        height: 100%;
    }
    body {
        background-color: ${theme.palette.background.main};
        color: ${theme.palette.background.contrastText};
    }
    h1, h2, h3, h4, h5, h6 {
        margin: 0 0 0.75em 0;
        font-weight: 400;
    }
    p {
        margin: 0;
    }
    em {
        font-style: normal;
    }
    p:not(:last-child),
    ul:not(:last-child),
    ol:not(:last-child),
    table:not(:last-child),
    blockquote:not(:last-child),
    dl:not(:last-child) {
        margin-bottom: 0.5em;
    }
    h1 {
        font-size: 2rem;
        font-family: ${theme.typography.families.headline}
    }
    h2 {
        font-size: 1.75rem;
        font-family: ${theme.typography.families.headline}
    }
    h3 {
        font-size: 1.5rem;
        font-family: ${theme.typography.families.headline}
    }
    h4 {
        font-size: 1.25rem;
    }
    h5 {
        font-size: 1rem; font-weight: ${theme.typography.bold} 
    }
    a {
        color: inherit;
    }
    hr {
        background-color: ${theme.palette.rule};
        height: 1px;
        border: none;
        margin: ${theme.baseUnit}px 0;
    }
    button {
        border: none;
        border-radius: 0;
        background: inherit;
    }
    input, select {
        border: none;
        outline: none;
    }
    table {
        border-collapse: collapse;
    }
    ::selection {
        color: ${theme.palette.secondary.contrastText};
        background: ${theme.palette.secondary.main};
    }
`);

export default BaseStyle;