import styled from "styled-components";

const OverlayContainer = styled("div")(({ theme }) => ({
    position: "fixed",
    top: theme.layout.headerHeight,
    bottom: theme.layout.footerHeight,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.overlay,
    overflowY: "auto"
}));

export default OverlayContainer;