import { addWeeks } from "date-fns";
import React, { createContext, useCallback, useEffect } from "react";
import { useLocalstorageState } from "rooks";

interface ICurrentHandout {
    endDate: string;
    handoutId: string;
    categoryId: string;
    scroll: string;
}

export interface ICurrentHandoutContext {
    setCurrentHandout: (handoutId: string, categoryId: string, scroll: string) => void;
    getCurrentHandout: (handoutId: string) => object;
}

const noopHandlers = {
    // eslint-disable-next-line
    setCurrentHandout: (handoutId: string, categoryId: string, scroll: string) => {},
    // eslint-disable-next-line
    getCurrentHandout: (handoutId: string) => ""
};

const CurrentHandoutContext = createContext<ICurrentHandoutContext>(noopHandlers);

export default CurrentHandoutContext;

const localStorageKey = "currenthandout";

export const CurrentHandoutProvider: React.FC = ({ children }) => {

    const [currentHandout, setCurrentHandoutls] = useLocalstorageState<Array<ICurrentHandout>>(localStorageKey, []);

    useEffect(() => {

        const now = new Date();

        try {
            setCurrentHandoutls(currentHandout.filter(c => new Date(c.endDate) > now));

        } catch (error) {

            console.error(`corrupted entries found in local-storage. Cleaning up "${localStorageKey}" key`, error);
            setCurrentHandoutls([]);
        }

    }, []);

    const setCurrentHandout = useCallback((handoutId: string, categoryId: string, scroll: string) => {

        const existingHandout = currentHandout.find(c => c.handoutId === handoutId);
        const handout = existingHandout || { handoutId, endDate: addWeeks(new Date(), 4).toJSON(), categoryId: "", scroll: "0" };

        if (!existingHandout) {
            currentHandout.push(handout);
        }

        handout.categoryId = categoryId;
        handout.scroll = scroll;
        setCurrentHandoutls([ ...currentHandout ]);

    }, [currentHandout, setCurrentHandoutls]);

    const getCurrentHandout = (handoutId: string) => currentHandout
        .find(h => h.handoutId === handoutId);

    return (
        <CurrentHandoutContext.Provider value={{ setCurrentHandout, getCurrentHandout }}>
            {children}
        </CurrentHandoutContext.Provider>
    );
};