import styled from "styled-components";
import CSSTransition from "react-transition-group/CSSTransition";

const SlideInFromRight = styled(CSSTransition).attrs({ classNames: "slideInFromRight", timeout: 150 })`
&.slideInFromRight-enter {
    transform: translateX(100%);
}
&.slideInFromRight-enter-active {
    transform: translateX(0%);
}
&.slideInFromRight-exit {
    transform: translateX(0%);
}
&.slideInFromRight-exit-active {
    transform: translateX(100%);
}
&.slideInFromRight-enter-active,
&.slideInFromRight-exit-active {
    transition: transform 150ms;
}
`;

export default SlideInFromRight;