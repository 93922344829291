import { CSSObject } from "styled-components";

export default function hideScrollbarCss(): CSSObject {

    return {
        "::-webkit-scrollbar": {
            display: "none"
        },
        "-ms-overflow-style": "none",
        "scrollbar-width": "none"
    };
} 