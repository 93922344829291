import { CSSObject } from "styled-components";

type Position = "top" | "right" | "bottom" | "left";

export default function positionCss(position: "abs" | "fix", docked?: Position): CSSObject {

    const allZeros: Record<Position, number | undefined> = {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    };

    if (docked === "top") {
        allZeros["bottom"] = undefined;
    } else if (docked === "right") {
        allZeros["left"] = undefined;
    } else if (docked === "left") {
        allZeros["right"] = undefined;
    } else if (docked === "bottom") {
        allZeros["top"] = undefined;
    }

    return {
        position: position === "fix" ? "fixed" : "absolute",
        ...allZeros
    };
}