import { CSSObject } from "styled-components";
import positionCss from "./positionCss";

export default function clickAreaCss(addPx = 10): CSSObject {
    return {
        "&:before": {
            content: "''",
            ...positionCss("abs"),
            margin: -addPx,
            background: "transparent"
        }
    };
}