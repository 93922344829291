import React, { HTMLProps } from "react";
import styled from "styled-components";
import clickAreaCss from "../../utils/clickAreaCss";
import FavListAdd from "../icons/FavListAdd";

const AddToListButton = styled(({ added, ...props }: HTMLProps<HTMLAnchorElement> & { added: boolean }) => (
    <a {...props}>
        <FavListAdd added={added} style={{ height: "100%" }} />
    </a>
))(({ theme, added }) => ({
    color: added ? theme.palette.tertiary.main : theme.palette.primary.main,
    display: "block",
    cursor: "pointer",
    position: "absolute",
    top: theme.baseUnit * 1.5,
    right: theme.baseUnit * 1.5,
    width: theme.baseUnit * 3.5,
    height: theme.baseUnit * 3.5,
    background: "rgba(255, 255, 255, 0.8)",
    boxShadow: "0 0 3px 3px rgba(255, 255, 255, 0.8)",
    ...clickAreaCss()
}));

export default AddToListButton;