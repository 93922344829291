import styled from "styled-components";
import dontForward from "../utils/dontForward";
import flexCss from "../utils/flexCss";
import valuesCss from "../utils/valuesCss";

const SecondaryButton = styled("button").withConfig(dontForward("bigPadding"))<{ bigPadding?: boolean }>(({ theme, bigPadding }) => ({
    ...flexCss("h", "start", "center"),
    justifyContent: "center",
    fontFamily: theme.typography.families.headline,
    fontWeight: theme.typography.bold,
    fontSize: 20,
    textTransform: "uppercase",
    lineHeight: "1",
    color: theme.palette.text,
    border: `3px solid ${theme.palette.text}`,
    background: theme.palette.white,
    padding: valuesCss("px", theme.baseUnit, theme.baseUnit * (bigPadding ? 4.5 : 2)),
    minHeight: 56,
    maxHeight: 80,
    opacity: 0.8,
    cursor: "pointer",
    "&:hover": {
        opacity: 1
    },
    [theme.media("sm")]: {
        fontSize: 22,
        minHeight: 60
    }
}));

export default SecondaryButton;