import { ApolloClient, useApolloClient } from "@apollo/client";
import React, { createContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ArticleDetailsFragment, ArticlesByIdsDocument, ArticlesByIdsQuery } from "../generated/graphql";
import isBrowserEnv from "../utils/isBrowserEnv";
import { IShoppingListItem } from "./IShoppingListItem";
import ShoppingListString from "./ShoppingListString";

export interface IShoppingListContext {
    add: (id: string) => void;
    remove: (id: string) => void;
    clearDone: () => void;
    has: (id: string, active: boolean) => boolean;
    get: (id: string) => IShoppingListItem | null;
    setDone: (id: string, done: boolean) => void;
    setAmount: (id: string, amount: number) => void;
    allItems: Array<IShoppingListItem>;
    createSetItemsUrl: (items: Array<IShoppingListItem>, path?: string) => string;
    setAllItems: (ids: Array<[id: string, amount: number, done: boolean]>) => Promise<void>;
    loading: boolean;
}

// eslint-disable-next-line
const noop = () => {};

const noopHandlers: IShoppingListContext = {
    add: noop,
    remove: noop,
    clearDone: noop,
    has: () => false,
    get: () => null,
    setDone: noop,
    setAmount: noop,
    allItems: [],
    createSetItemsUrl: () => "",
    setAllItems: () => Promise.resolve(),
    loading: false
};

async function fetchNewIds(
    ids: Array<string>,
    apollo: ApolloClient<unknown>,
    setLoading: (loading: boolean) => void
): Promise<Array<ArticleDetailsFragment>> {

    setLoading(true);

    try {
        const response = await apollo.query<ArticlesByIdsQuery>({
            query: ArticlesByIdsDocument,
            variables: {
                ids
            },
            fetchPolicy: "cache-first"
        });

        setTimeout(() => setLoading(false), 0); // wait til next tick to run sync code first

        return response.data.currentHandout?.articlesByIds || [];

    } catch (error) {
        console.error("Fetching shopping list articles failed");

        setLoading(false);

        return [];
    }
}

function useCreateContext(): IShoppingListContext {

    const [items, setItems] = useState(new Array<IShoppingListItem>());
    const apollo = useApolloClient();
    const [loading, setLoading] = useState(false);

    return {
        addOwnArticle: (id: string) => {
            if (!items.some(i => i.id === id)) {
                setItems(
                    items.concat({
                        id,
                        done: false,
                        amount: 1,
                        article: { name: 'Eigener Artikel', descriptionLines: '', offeredPrice: 0, imageUrl: '' }
                    })
                );
            }
        },
        add: async (id: string) => {

            if (!items.some(i => i.id === id)) {

                const newItem = await fetchNewIds([id], apollo, setLoading);
                if (newItem[0]) {
    
                    setItems(
                        items.concat({
                            id,
                            done: false,
                            amount: 1,
                            article: newItem[0]
                        })
                    );
                }
            }
        },
        remove: (id: string) => {
            setItems(
                items.filter(item => item.id !== id)
            );
        },
        clearDone: () => {
            setItems(
                items.filter(item => !item.done)
            );
        },
        has: (id: string, active: boolean) => {
            return items.some(item => item.id === id && (!active || !item.done));
        },
        get: (id: string) => {
            return items.find(item => item.id === id) || null;
        },
        setDone: (id: string, done: boolean) => {
            const foundItem = items.find(item => item.id === id);
            if (foundItem) {
                foundItem.done = done;
                setItems([...items]);
            }
        },
        setAmount: (id: string, amount: number) => {
            const foundItem = items.find(item => item.id === id);
            if (foundItem && amount > 0) {
                foundItem.amount = amount;
                setItems([...items]);
            }
        },
        setName: (id: string, name: string) => {
            const foundItem = items.find(item => item.id === id);
            if (foundItem) {
                foundItem.article.name = name;
                setItems([...items]);
            }
        },
        allItems: items,
        createSetItemsUrl: (items: Array<IShoppingListItem>, path?: string) => {

            const hostPart = isBrowserEnv() && `https://${window.location.hostname}` || "";

            const shoppingListString = new ShoppingListString(items.map(({ id, amount, done }) => [id, amount, done]));

            const params = new URLSearchParams({
                [paramName]: shoppingListString.toString()
            });

            return `${hostPart}/${path || ""}?${params.toString()}`;
        },
        setAllItems: async (entries: Array<[id: string, amount: number, done: boolean]>) => {

            const newItems = await fetchNewIds(entries.map(e => e[0]), apollo, setLoading);

            const fullEntries = newItems.map<IShoppingListItem>(article => {

                const [id, amount, done] = entries.find(([id]) => id === article.id) || ["", 1, false];

                return { id, done, amount, article };
            });

            entries.map(e => {
                if (e[0] < 0) {
                    fullEntries.push({
                        id: e[0],
                        amount: e[1],
                        done: e[2],
                        article: {name: e[3] || "", offeredPrice: 0, descriptionLines: '', imageURL: ''}
                    });
                }
            });

            setItems(fullEntries);
        },
        loading
    };
}

const ShoppingListContext = createContext<IShoppingListContext>(noopHandlers);

const keyName = "shopping-list";

const paramName = "set-list-items";

export const ShoppingListProvider: React.FC = ({ children }) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const contextValue = useCreateContext();

    useEffect(() => {

        const listParam = searchParams.get(paramName);
        const shoppingListString = ShoppingListString.parse(listParam || "");

        if (shoppingListString) {

            contextValue.setAllItems(shoppingListString.items);

            searchParams.delete(paramName);
            setSearchParams(searchParams, { replace: true });

        } else if (localStorage && localStorage.getItem(keyName)) {

            try {

                const itemRaw = JSON.parse(localStorage.getItem(keyName) || "[]");

                if (Array.isArray(itemRaw)) {

                    const allFormattedCorrectly = itemRaw.every(a => !!a && Array.isArray(a) && a.length >= 3);

                    if (!allFormattedCorrectly) {
                        throw new Error("bad format");
                    }

                    contextValue.setAllItems(itemRaw);
                }

            } catch (error) {
                console.error("Couldn't restore shopping list from local storage");
                localStorage.setItem(keyName, "");
            }
        }

    }, []);

    useEffect(() => {

        if (contextValue.allItems.length >= 0) {

            try {
                const json = JSON.stringify(contextValue.allItems.map(i => ([i.id, i.amount, i.done, i.article.name])));
                localStorage.setItem(keyName, json);

            } catch (error) {
                console.error("shopping list could not be saved to local storage");
            }
        }

    }, [contextValue.allItems]);

    return (
        <ShoppingListContext.Provider value={contextValue}>
            {children}
        </ShoppingListContext.Provider>
    );
};

export default ShoppingListContext;