import styled from "styled-components";
import dontForward from "../../utils/dontForward";
import valuesCss from "../../utils/valuesCss";

const ArticleTileInfo = styled("div").withConfig(dontForward("horizontal", "paddingTop"))<{ horizontal: boolean, paddingTop: boolean }>(({ theme, horizontal, paddingTop }) => ({
    backgroundImage: horizontal ?
        "url(/assets/images/tear-off-v.png)" :
        "url(/assets/images/tear-off-h.png)",
    ...(horizontal ? {
        padding: valuesCss("px", theme.baseUnit * (paddingTop ? 5 : 2), theme.baseUnit * 2, theme.baseUnit * 2, theme.baseUnit * 2),
        backgroundPosition: "left center",
        backgroundRepeat: "repeat-y",
        backgroundSize: "16px auto"
    } : {
        padding: theme.baseUnit * 2,
        backgroundPosition: "top center",
        backgroundRepeat: "repeat-x",
        backgroundSize: "auto 16px"
    }),
    "& > p:not(:last-child)": {
        marginBottom: theme.baseUnit
    }
}));

export default ArticleTileInfo;