import React from "react";
import { SvgIconProps } from "./SvgIconProps";

const FavListAdd: React.FC<SvgIconProps & { added?: boolean }> = ({ color, added, ...props }) => (
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinecap="round" strokeMiterlimit="3" {...props}>
        <path d="M184.454 15.553v16.592l1.664-1.08 1.636.984 1.636-.984 1.636.984 1.637-.984 1.636.984 1.636-.984 1.636.984 1.637-.984 1.636 1.08V15.553h-16.39z" fill="none" stroke={color || "currentColor"} strokeWidth="1.51px" transform="matrix(-1 0 0 1 203.224 -13.172)"/>
        <path d="M189.501 22.904h6.461" fill="none" stroke={color || "currentColor"} strokeWidth="1.51px" strokeLinecap="butt" transform="matrix(-1 0 0 1 203.252 -13.2)"/>
        {!added && (
            <path d="M189.501 22.904h6.461" fill="none" stroke={color || "currentColor"} strokeWidth="1.51px" strokeLinecap="butt" transform="matrix(0 1 1 0 -12.383 -183.027)"/>
        )}
    </svg>
);

export default FavListAdd;