import React from "react";
import { SvgIconProps } from "./SvgIconProps";

const CloseIcon: React.FC<SvgIconProps> = ({ color, ...props }) => (
    <svg viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="3" {...props}>
        <path d="m3074.93 172.127 9.69 9.69" fill="none" stroke={color || "currentColor"} strokeWidth="2px" transform="translate(-3071.772 -168.964)"/>
        <path d="m3074.93 172.127 9.69 9.69" fill="none" stroke={color || "currentColor"} strokeWidth="2px" transform="rotate(90 1628.374 -1443.395)"/>
    </svg>
);

export default CloseIcon;