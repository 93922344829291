import React, { useCallback, useContext, useEffect } from "react";
import { createContext, useState } from "react";

interface IPopupContext {
    openedPopupId: string | null;
    setOpenedPopupId: (id: string | null) => void;
}

const OverlayContext = createContext<IPopupContext>({
    openedPopupId: null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,  @typescript-eslint/no-empty-function
    setOpenedPopupId: (id: string | null) => {}
});

export const OverlayContextProvider: React.FC = ({ children }) => {

    const [openedPopupId, setOpenedPopupId] = useState<string | null>(null);

    useEffect(() => {
        if (openedPopupId) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [openedPopupId]);

    const value: IPopupContext = {
        openedPopupId,
        setOpenedPopupId
    };

    return (
        <OverlayContext.Provider value={value}>
            {children}
        </OverlayContext.Provider>
    );
};

export function useOverlayState(id: string): [isOpen: boolean, setOpen: (open: boolean) => void] {

    const overlayContext = useContext(OverlayContext);

    useEffect(() => () => {

        if (overlayContext.openedPopupId === id) {
            overlayContext.setOpenedPopupId(null);
        }
    }, [overlayContext.openedPopupId, id]);

    const setOpen = useCallback((open: boolean) => {

        if (open) {
            overlayContext.setOpenedPopupId(id);

        } else if (overlayContext.openedPopupId) {
            overlayContext.setOpenedPopupId(null);
        }

    }, [overlayContext.openedPopupId, id]);

    return [
        overlayContext.openedPopupId === id,
        setOpen
    ];
}

export default OverlayContext;