import React, { useContext } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
import CategorySeenContext from "../../contexts/CategorySeenContext";
import TrackingContext from "../../contexts/TrackingContext";
import { useCategoriesQuery, usePreviewCategoriesQuery } from "../../generated/graphql";
import CategoryMenu from "./CategoryMenu";
import HandoutLayoutRoot from "./HandoutLayoutRoot";
import HandoutImagePopup from "./HandoutImagePopup";

const Handout: React.FC = () => {

    const { category, preview_date } = useParams();
    const navigate = useNavigate();

    const { data: realData } = useCategoriesQuery({
        skip: !!preview_date
    });
    const { data: previewData } = usePreviewCategoriesQuery({
        skip: !preview_date,
        variables: {
            atDate: preview_date || ""
        }
    });

    const categorySeenContext = useContext(CategorySeenContext);

    const trackingContext = useContext(TrackingContext);

    const handout = realData?.currentHandout || previewData?.handout;
    const categories = handout?.categories;

    const onCategoryClick = (id: string) => {
        trackingContext.trackEvent("categoryClick", {
            handoutId: handout?.id || "",
            id
        });
        navigate(id);
    };

    return (
        <>
            {categories && !!categories.length && (
                <CategoryMenu
                    categories={categories.map(c => ({ ...c, seen: categorySeenContext.hadSeenCategory(handout?.id || "", c.id) }))}
                    activeId={category}
                    onCategoryClick={onCategoryClick}
                    onAllCategoriesClick={() => navigate("")}
                />
            )}
            <HandoutLayoutRoot>
                <Outlet />
            </HandoutLayoutRoot>
            {handout && (
                <HandoutImagePopup
                    documentUrl={handout?.documentUrl || null}
                />
            )}
        </>
    );
};

export default Handout;