import React, { forwardRef } from "react";
import styled from "styled-components";
import ArticleTileContainer from "../../common/article/ArticleTileContainer";
import ProductImage from "../../common/article/ProductImage";
import { ArticleDetailsWithVariantsFragment } from "../../generated/graphql";
import dontForward from "../../utils/dontForward";

const ImageTileDisplay = styled("div").withConfig(dontForward("horizontal"))<{ horizontal: boolean }>(({ horizontal, theme }) => ({
    position: "relative",
    flex: horizontal ? "0 0 calc(50% - 8px)" : undefined,
    
    ...(horizontal && ({
        minHeight: 256
    })),
    [theme.media("md")]: {
        flex: horizontal ? "0 0 calc(100% - 200px)" : undefined
    }
}));

const ImageTileDisplayPointer = styled(ImageTileDisplay).withConfig(dontForward("horizontal"))<{ horizontal: boolean }>(({ horizontal, theme }) => ({
    cursor: "pointer"
}));

const ImageTileContainerMain = styled(ArticleTileContainer).withConfig(dontForward("double", "clickable"))<{ double: boolean, clickable: boolean, color: string }>(({ double, clickable, color }) => ({
    gridColumn: double ? "span 2" : undefined,
    cursor: clickable ? "pointer" : undefined,
    justifyContent: "center"
}));


const ImageProductImage = styled(ProductImage)({
    padding: "0px"
});

interface IImageTileProps {
    article: ArticleDetailsWithVariantsFragment;
    noExpand: boolean;
    documentUrl: string | null;
}

const ImageTile = forwardRef<HTMLDivElement, IImageTileProps>(({
    article: {
        id,
        name,
        descriptionLines,
        offeredPrice,
        normalPrice,
        special,
        baseUnitPrice,
        baseUnitPriceSammel,
        imageUrl,
        attributes,
        hideNormalPrice,
        hasVariants,
        deCardPoints
    }, documentUrl
}, ref) => {

    if (name == '[HO_BLAETTERKATALOG]') {
      name = documentUrl;
    }

    const target = name.startsWith("http") ? "_blank" : "";

    return (
        <ImageTileContainerMain
            ref={ref}
            id={id}
            horizontal={false}
            double={true}
            clickable={false}
        >
            {name != 'nohref' && (
                <a href={name} target={target}>
                    <ImageTileDisplayPointer horizontal={false}>
                        <ImageProductImage src={imageUrl || undefined} />
                    </ImageTileDisplayPointer>
                </a>
            )}
            {name == 'nohref' && (
                <ImageTileDisplay horizontal={false}>
                    <ImageProductImage src={imageUrl || undefined} />
                </ImageTileDisplay>
            )}
        </ImageTileContainerMain>
    );
});

export default React.memo(ImageTile);