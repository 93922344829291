import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import PrimaryButton from "../style/PrimaryButton";
import flexCss from "../utils/flexCss";
import { SvgIconProps } from "./icons/SvgIconProps";

interface IPrimaryIconButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    IconLeft?: React.ComponentType<SvgIconProps>;
    IconRight?: React.ComponentType<SvgIconProps>;
    size?: "large";
}

const PrimaryIconButton = styled(({ children, IconLeft, IconRight, ...props }: PropsWithChildren<IPrimaryIconButtonProps>) => (
    <PrimaryButton {...props}>
        {IconLeft && <IconLeft />}
        <span>{children}</span>
        {IconRight && <IconRight />}
    </PrimaryButton>
))(({ IconLeft, IconRight }) => ({
    ...flexCss("h", "center", "center"),
    textAlign: (!IconLeft && !!IconRight && "right") || (!IconRight && !!IconLeft && "left") || undefined,
    "& > svg": {
        height: "1em",
        marginLeft: "0.5em",
        flex: "0 0 auto"
    }
}));

export default PrimaryIconButton;