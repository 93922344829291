import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import styled from "styled-components";
import { useOverlayState } from "../contexts/OverlayContext";
import flexCss from "../utils/flexCss";
import Footer from "./Footer";
import Header from "./Header";
import SideMenu from "./SideMenu";
import {
    useHandoutCategoriesStatsAndDocumentUrlQuery,
    usePreviewHandoutCategoriesStatsAndDocumentUrlQuery
} from "../generated/graphql";
import MarketsMenu from "./MarketsMenu";
import { startOfWeek, endOfWeek, getDay, addDays, getYear, getWeek, isValid } from "date-fns";

const Container = styled("section")({
    ...flexCss("v", "space-between"),
    position: "relative",
    width: "100%",
    overflow: "hidden"
});

const Main = styled("div")(({ theme, isname, name, handout }) => ({
    paddingTop: theme.layout.headerHeight,
    paddingBottom: theme.layout.footerHeight,
    backgroundImage: handout && isname ? "url(" + name + ")" : null,
    minHeight: "100vh"
}));

const Root: React.FC = () => {

    const [sideMenuOpen, setSideMenuOpen] = useOverlayState("side-menu");
    const [marketsMenuOpen, setMarketsMenuOpen] = useOverlayState("markets-menu");
    const [name, setName] = useState("");
    const [isname, setIsName] = useState(false);

    const { preview_date } = useParams();

    const { data: realData } = useHandoutCategoriesStatsAndDocumentUrlQuery({
        skip: !!preview_date
    });
    const { data: previewData } = usePreviewHandoutCategoriesStatsAndDocumentUrlQuery({
        skip: !preview_date,
        variables: {
            atDate: preview_date || ""
        }
    });

    const handout = realData?.currentHandout || previewData?.handout || null;

    let now = new Date();

    if (preview_date) {
        const previewDate = new Date(preview_date);
        if (isValid(previewDate)) {
            now = previewDate;
        }
    }

    if (getDay(now) === 0) {
        now = addDays(now, 1); // show next week on sundays
    }

    const from = startOfWeek(now, { weekStartsOn: 1 });
    const to = endOfWeek(now, { weekStartsOn: 1 });
    const fromYear = getYear(from);
    const toYear = getYear(to);
    const fromWeekFormatted = getWeek(from, { weekStartsOn: 1, firstWeekContainsDate: 4 }).toString().padStart(2, 0);

    useEffect(() => {
       fetch('https://portal.stroetmann.de/bilder/output/Messeportal/digitalerhandzettel/background/' + toYear + '_' + fromWeekFormatted + '.png').then(
           res => res.text()).then(res => setName(res));
    }, [])

    useEffect(() => {
        if (isname == false) {
            if (name == '' || name == 'Datei existiert nicht.') {
            } else {
                setName('https://portal.stroetmann.de/bilder/output/Messeportal/digitalerhandzettel/background/' + toYear + '_' + fromWeekFormatted + '.png');
                setIsName(true);
            }
        }
    }, [name])

    return (
        <Container>
            <Header
                onBurgerMenuClick={() => setSideMenuOpen(!sideMenuOpen)}
                documentUrl={handout?.documentUrl || null}
                marketNamAndSelect={handout?.marketNamAndSelect || null}
            />
            <Main
                isname={isname}
                name={name}
                handout={handout}>
                <Outlet />
                <SideMenu
                    open={sideMenuOpen}
                    onClose={() => setSideMenuOpen(false)}
                    categories={handout?.categories || []}
                    handoutId={handout?.id}
                />
                <MarketsMenu
                    open={marketsMenuOpen}
                    onClose={() => setMarketsMenuOpen(false)}
                />
            </Main>
            <Footer footerDisableSubscribe={handout?.footerDisableSubscribe|| null}/>
        </Container>
    );
};

export default Root;