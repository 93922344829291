import React from "react";
import styled from "styled-components";

const noFractionFormat =  new Intl.NumberFormat(undefined, { maximumFractionDigits: 0 });

const OfferedPriceBase = styled("div")(({ theme }) => ({
    lineHeight: "1",
    fontSize: 26,
    fontFamily: theme.typography.families.prices,
    position: "relative",
    letterSpacing: "0.02em"
}));

const Skew = styled("div")({
    transform: "skew(-12deg, 0)"
});

const OfferedPriceDecimals = styled("span")({
    position: "relative",
    left: "-0.25em",
    bottom: "0.58em",
    fontSize: "0.6em",
    lineHeight: 1.2,
    marginRight: "-0.2em"
});

interface IOfferedPriceProps {
    price: number;
    skew?: boolean;
    className?: string;
}

const OfferedPrice: React.FC<IOfferedPriceProps> = ({ price, skew, className }) => {

    const [offeredIntegerPart, offeredDecimalPart] = price.toFixed(2).split(".");

    const content = (
        <>
            {noFractionFormat.format(+offeredIntegerPart)},
            <OfferedPriceDecimals>
                <span>{offeredDecimalPart}</span>
            </OfferedPriceDecimals>
        </>
    );

    return (
        <OfferedPriceBase className={className}>
            {skew ? (
                <Skew>
                    {content}
                </Skew>
            ) : content}
        </OfferedPriceBase>
    );
};

export default OfferedPrice;